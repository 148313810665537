import styled from "styled-components"
import theme from "../../theme/theme"

export const ModuleList = styled.ul`
    list-style: none;
    margin: 10px 0;
    padding: 0;
    >li {
        border-radius: 4px;
        padding: 12px 12px;
        background-color: ${theme.colors.darkSpace10};
        &:hover {
            background-color: ${theme.colors.darkSpace30};
            cursor: pointer;
        }
        margin: 4px;
    }
`
