import { ListItem, StyledLink, StyledList } from "./Styles";

const AccessControlList = ({ roles, accessRules }) => {
    
    return <>
        <StyledList>
            {roles.map(role => <ListItem key={role.coreId}>
                <div><StyledLink to={`/access/role/${role.coreId}`}>{role.name}</StyledLink></div>
            </ListItem>)}
        </StyledList>
    </>
}

export default AccessControlList;