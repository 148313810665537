import styled, { css } from "styled-components";
import theme from "../ui/theme/theme";

const Container = styled.div`
    display: flex;
    border-bottom: 1px solid ${theme.colors.darkSpace70};
    width: 100%;
`

const Tab = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`

const StyledButton = styled.button`

    width: 100%;
    padding: 12px 16px;
    border-radius: 2px;
    background-color: transparent;
    border: none;
    font-size: ${theme.fontSize.medium};

    &:hover {
        background-color: ${theme.colors.darkSpace80};
        cursor: pointer;
    }

    ${props => props.selected && css`
        background-color: ${theme.colors.darkSpace80};
    `}
`

const TabSelector = ({ tabs, selectedTab, onClick }) => {

    return <Container>
        {tabs.map(tab => <Tab key={tab.key}>
            <StyledButton onClick={() => onClick(tab)} selected={tab.key === selectedTab.key}>
                {tab.display}
            </StyledButton>
        </Tab>
        )}
    </Container>
}

export default TabSelector;