import styled, { css } from 'styled-components';
import Checkbox from '../../../ui/components/Checkbox';
import { displayModes } from '../editableTable/tableTypes';
import theme from '../../../ui/theme/theme';

const Container = styled.div`
    display: flex;
    ${props => props.centered && css`
        justify-content: center;
    `}
`

const CheckboxField = ({ object, column, updateObject, cellControl }) => {
    const checked = Number(object[column.name]) ? 1 : 0;

    return <Container centered={cellControl?.displayMode === displayModes.TABLE}>
        <Checkbox
            checked={checked}
            onChange={() => updateObject({ [column.name]: +!checked })}
            backgroundColor={theme.colors.darkSpace50}
        />
    </Container>
}

export default CheckboxField;