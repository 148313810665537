import { capitalizeFirstLetter } from "../../../../../scripts/common";
import CellDropdown from "../CellDropdown";

const ColumnSelector = ({
    object, 
    columns, 
    selectColumn
}) => {
    
    const columnItems = columns.map(col => ({ value: col.name, displayValue: col.displayName || capitalizeFirstLetter(col.name) }));
    const selectedColumnItem = columnItems.find(col => col.value === object.column);

    return <CellDropdown
        items={columnItems}
        selectedItem={selectedColumnItem}
        onClick={(value) => selectColumn(value)}
        cellControl={{}}
        excludeNone={true}
    />
}

export default ColumnSelector;