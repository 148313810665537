import { useState, useEffect, useRef } from "react";
import { getRemoteUpdatedProps } from "../tableUtils";
import { propHasChanged } from "../../../../scripts/state/stateUtils";

const useEditableForm = ({ parentObject, saveObject }) => {
    const [updatedObject, setUpdatedObject] = useState(parentObject);
    const updatedKeys = useRef([]);

    useEffect(() => {
        handleRemoteUpdate(parentObject);
    }, [parentObject])

    const setUpdatedKeys = (keys) => {
        updatedKeys.current = keys;
    }

    const handleRemoteUpdate = (remoteObject) => {
        const remoteUpdatedObject = getRemoteUpdatedProps(remoteObject, updatedObject, updatedKeys.current);
        setUpdatedObject(remoteUpdatedObject);
    }

    const updateObject = (updatedProps) => {
        let changedKeys = [...updatedKeys.current];
        Object.keys(updatedProps).forEach(key => {
            if (propHasChanged(parentObject[key], updatedProps[key])) {
                if (!changedKeys.includes(key)) {
                    changedKeys.push(key);
                }
            } else if (changedKeys.includes(key)) {
                changedKeys = changedKeys.filter(changedKey => changedKey !== key);
            }
        });
        setUpdatedKeys(changedKeys);
        setUpdatedObject({ ...updatedObject, ...updatedProps });
    }

    const isEdited = (key) => {
        if (key) {
            return updatedKeys.current.includes(key);
        } else {
            return updatedKeys.current.length;
        }
    }

    const getChangedProps = () => {
        const changedProps = {};
        if (parentObject.coreId) {
            changedProps.coreId = parentObject.coreId
        }
        updatedKeys.current.forEach(key => {
            changedProps[key] = updatedObject[key];
        })
        return changedProps;
    }

    const saveHandler = async () => {
        try {
            const changedprops = getChangedProps();
            await saveObject(changedprops);
            setUpdatedKeys([]);
            
        } catch (err) {
            console.log('debug save handler error', err);
        }
    }

    return [updatedObject, updateObject, isEdited, saveHandler];
}

export default useEditableForm;