import { useState } from "react";
import { Button } from "@mui/material";
import Modal from "../../../../ui/components/modal/Modal";
import { FormContainer } from "../../editableTable/styles/FormStyles";
import { cellStates } from "../../editableTable/tableTypes";

const ModalField = (props) => {
    const [showModal, setShowModal] = useState(false);

    if (props.cellState === cellStates.FORM) {
        return <>{props.children}</>
    } else {
        return <>
            {props.display}<Button onClick={() => setShowModal(true)}>Show</Button>
            {showModal ? <>
                <Modal show={showModal} close={() => setShowModal(false)}>
                    <FormContainer>
                        {props.children}
                    </FormContainer>
                </Modal>
            </> : <></>}
        </>
    }
}

export default ModalField;

