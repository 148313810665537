import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getApi } from "../remote/api";
import { useEffect, useState } from "react";
import { Container } from "@mui/material";
import useTableMetaData from "../hooks/useTableMetaData";
import EditableForm from "../table/editableTable/EditableForm";
import { capitalizeFirstLetter, parseSearchParams } from "../../scripts/common";
import ReturnButton from "../../ui/components/button/ReturnButton";
import { getPrefilledValues } from "./formUtils";
import RelatedListContainer from "./RelatedListContainer";

const FormView = ({ }) => {
    const { apiPath, table, coreId } = useParams();
    const api = getApi(apiPath)
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [record, setRecord] = useState();
    const [isNewRecord, setIsNewRecord] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const { columns, references, choices, clientRules } = useTableMetaData({ apiPath, table });

    useEffect(() => {
        if (coreId === 'new' && columns) {
            initNewRecord();
        } else {
            getTableData();
        }

    }, [table, apiPath, coreId, columns]);

    const initNewRecord = () => {
        setIsNewRecord(true);
        const urlFilter = parseSearchParams(searchParams);
        const prefilledValues = getPrefilledValues(columns, references, choices, urlFilter);
        const prefilledRecord = { ...record, ...prefilledValues };
        setRecord({ ...prefilledRecord })
    }

    const getTableData = async () => {
        try {
            setRecord(null);
            const recRes = await api.getRecord(table, coreId);
            setIsNewRecord(false);
            setRecord(recRes);
        } catch (err) {
            if (err.response?.status === 404) {
                initNewRecord();
            } else if (err?.response?.status === 403) {
                setErrorMessage('You do not have access to this content');
            }
        }
    }

    const updateRecord = async () => {
        try {
            getTableData();
        } catch (err) {

        }
    }

    const saveObject = async (object) => {
        try {
            console.log('debug save');
            await api.updateRecord(table, object);
            await updateRecord();
        } catch (err) {
            if (err?.response?.status === 403) {
                setErrorMessage('You do not have access to save');
                await updateRecord()
            }
        }
    }

    const addObject = async (object) => {
        try {
            const addedRecord = await api.addRecord(table, object);
            navigate(`/form/${apiPath}/${table}/${addedRecord.coreId}`);
        } catch (err) {
            if (err?.response?.status === 403) {
                setErrorMessage('You do not have access save');
            }
        }
    }

    const deleteObject = async (object) => {
        try {
            await api.deleteRecord(table, object.coreId);
            await updateRecord();
            // go to table
        } catch (err) {
            if (err?.response?.status === 403) {
                setErrorMessage('You do not have access to delete');
            }
        }
    }

    return <Container maxWidth="lg" sx={{ backgroundColor: 'white', py: 10, px: 0, width: '100vw' }}>
        
        {/* <ReturnButton navigateBack={() => navigate(`/${apiPath}/${table}`)} /> */}
        {columns && record && references && choices && clientRules ? <>
            <EditableForm
                columns={columns}
                onSubmit={(object) => {
                    if (isNewRecord) {
                        addObject(object)
                    } else {
                        saveObject(object)
                    }
                }}
                record={record}
                references={references}
                choices={choices}
                clientRules={clientRules}
                title={capitalizeFirstLetter(table)}
                isNewRecord={isNewRecord}
            />
        </> : <></>}

        {errorMessage ? <>
            {errorMessage}
        </> : <></>}

        {!isNewRecord ? <>
            <RelatedListContainer
                table={table}
                coreId={coreId}
                apiPath={apiPath}
            />
        </> : <></>}
    </Container>

}

export default FormView;