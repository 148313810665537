import { useState, useEffect, useRef } from 'react';
import { fieldTypes, getFieldComponent } from "../fields/fieldUtils";
import { HeaderRow, Table, TableCell } from "./styles/TableStyles";
import { capitalizeFirstLetter, getUuid } from "../../../scripts/common";
import DeleteField from "../fields/DeleteField";
import { cellStates } from "./tableTypes";
import useOutsideClick from "../../../common/hooks.js/useOutsideClick";
import { applyClientRules } from '../fields/components/logicFields/clientRuleUtils';
import { useParams } from 'react-router-dom';


const EditableTable = ({
    objects,
    updateObjects,
    isEdited,
    columns,
    references,
    choices,
    clientRules,
    idField,
    deleteObject
}) => {
    const { apiPath, table } = useParams();
    const [markedCell, _setMarkedCell] = useState({ x: 0, y: 0 });
    const [cellState, _setCellState] = useState(cellStates.NONE);
    const cellStateRef = useRef(cellState);
    const markedCellRef = useRef(markedCell);
    const callbacks = useRef([]);
    const outsideClickRef = useOutsideClick(outsideClickHandler);

    const setCellState = (state) => {
        cellStateRef.current = state;
        _setCellState(state);
    }

    const setMarkedCell = (coordinates) => {
        markedCellRef.current = coordinates;
        _setMarkedCell(coordinates);
    }

    const navigateToCell = (coordinates) => {
        setCellState(cellStates.NONE);
        _setMarkedCell(coordinates);
    }

    const addCallback = (fn) => {
        const id = getUuid();
        callbacks.current.push({ id, fn });
        return id;
    }

    const removeCallback = (id) => {
        callbacks.current = callbacks.current.filter(callback => callback.id !== id);
    }

    useEffect(() => {
        window.addEventListener("keydown", downHandler);
        return () => {
            window.removeEventListener("keydown", downHandler);
        }
    }, [objects?.length]);


    const downHandler = (event) => {
        const key = event.key;
        for (let i = 0; i < callbacks.current.length; i++) {
            const handled = callbacks.current[i].fn(event);
            if (handled) {
                return;
            }
        }

        if (isArrowKey(key) && markedCellRef.current !== null && cellStateRef.current === cellStates.NONE) {
            event.preventDefault();
            navigateToCell(current => getNewCoordinates(current, key, objects.length, columns.length));
        } else if (markedCellRef.current !== null && key === 'Enter') {
            event.preventDefault();
            if (cellStateRef.current === cellStates.ACTIVE) {
                setCellState(cellStates.NONE)
            } else {
                setCellState(cellStates.ACTIVE)
            }
        }
    }

    function outsideClickHandler(event) {
        setMarkedCell(null);
    }

    const onCellClick = (ix, iy) => {
        setMarkedCell({ x: ix, y: iy });
        setCellState(cellStates.ACTIVE);
    }

    const renderObject = (columns, object, iy) => {
        const updateObject = (updatedProps) => updateObjects(object[idField], updatedProps);
        const { updatedRecord, columnProps } = applyClientRules(clientRules, object, columns);
        if (Object.keys(updatedRecord).length) {
            updateObject(updatedRecord);
        }

        return <tr key={iy}>
            {columns.map((column, ix) => {
                const marked = markedCell && ix === markedCell.x && iy === markedCell.y;
                const cellControl = { cellState: getCellState(marked, cellState), addCallback, removeCallback };
                const isUpdated = isEdited(object[idField], column.name);

                const Field = getFieldComponent(column.type);
                let fieldReferences = [];
                if (column.type === fieldTypes.reference) {
                    fieldReferences = references[column.referenceTable];
                }
                let fieldChoices = [];
                if (column.type === fieldTypes.choice) {
                    fieldChoices = choices[column.name];
                }

                let formLink;
                if (column.type === fieldTypes.reference) {
                    const referenceApiPath = column.isCoreReference ? 'core' : apiPath;
                    formLink = `/form/${referenceApiPath}/${column.referenceTable}/${object[column.name]}`;
                } else if (column.isDisplay) {
                    formLink = `/form/${apiPath}/${table}/${table === 'table' ? object.name : object.coreId}`;
                }

                if (!columnProps[column.name]?.hide) {
                    return <TableCell
                        key={ix}
                        marked={marked}
                        cellState={cellControl.cellState}
                        updated={isUpdated}
                        onClick={() => onCellClick(ix, iy)}
                    >
                        <Field
                            column={column}
                            object={object}
                            updateObject={updateObject}
                            cellState={cellControl.cellState}
                            editable={!columnProps[column.name]?.disable && column.editable && !object.preventUpdate}
                            references={fieldReferences}
                            choices={fieldChoices}
                            formLink={formLink}
                        />
                    </TableCell>
                } else {
                    return <td key={ix}>N/A</td>
                }
            })}
            {deleteObject ? <>
                {!object.preventDelete ? <>
                    <DeleteField onClick={async () => await deleteObject(object)} />
                </> : <>
                    <td></td>
                </>}
            </> : <></>}
        </tr>
    }

    return <Table>
        <thead>
            <HeaderRow>
                {columns.map(column => {
                    return <th key={column.name}>
                        {capitalizeFirstLetter(column.displayName || column.name)}
                    </th>
                })}
                {deleteObject ? <th></th> : <></>}
            </HeaderRow>
        </thead>

        <tbody ref={outsideClickRef}>
            {objects.map((object, iy) => renderObject(columns, object, iy))}
        </tbody>

    </Table>
}

export default EditableTable;


const isArrowKey = (key) => {
    return ['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown'].includes(key);
}

const getNewCoordinates = (current, key, nrows, ncols) => {
    const newCoordinates = { ...current };
    switch (key) {
        case 'ArrowRight':
            newCoordinates.x = Math.min(ncols - 1, newCoordinates.x + 1);
            break;
        case 'ArrowLeft':
            newCoordinates.x = Math.max(0, newCoordinates.x - 1);
            break;
        case 'ArrowDown':
            newCoordinates.y = Math.min(nrows - 1, newCoordinates.y + 1);
            break;
        case 'ArrowUp':
            newCoordinates.y = Math.max(0, newCoordinates.y - 1);
            break;
    }

    return newCoordinates;
}

const getCellState = (marked, cellState) => {
    return marked ? cellState : cellStates.NONE;
}

