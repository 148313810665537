import styled, { css } from 'styled-components';
import { cellStates } from '../editableTable/tableTypes';
import StringField from './StringField';
import theme from '../../../ui/theme/theme';

const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    ${props => props.active && css`
      background-color: ${theme.colors.darkSpace10};
    `}
`

const StyledNumberInput = styled.input`
    border: none;
    outline: none;
    background-color: transparent;
`

const NumberField = ({ object, column, updateObject, cellState, editable }) => {
    const disabled = !editable;
    const value = object[column.name];
    const active = cellState === cellStates.ACTIVE || cellState === cellStates.FORM;
    return <Container active={active}>
        {!disabled ? <>
            <StyledNumberInput
                type="number"
                value={value}
                onChange={(e) => updateObject({ [column.name]: e.target.value })}
            />
        </> : <>
            {value}
        </>}
    </Container>
}

export default NumberField;