import { fieldTypes } from "../table/fields/fieldUtils";

const getFilteredChoices = (choices, column, urlFilter) => {
    if (urlFilter?.[column.name]) {
        choices = choices.filter(item => item.value === urlFilter[column.name]);
    }
    return choices;
}

const getPrefilledValues = (columns, references, choices, urlFilter) => {
    const prefilledValues = {};
    columns.forEach(column => {
        if (column.type === fieldTypes.reference) {
            let fieldReferences = getFilteredChoices(references[column.referenceTable], column, urlFilter)
            if (fieldReferences.length === 1) {
                prefilledValues[column.name] = fieldReferences[0].value;
            }
        }

        if (column.type === fieldTypes.choice) {
            let fieldChoices = getFilteredChoices(choices[column.name], column, urlFilter)
            if (fieldChoices.length === 1) {
                prefilledValues[column.name] = fieldChoices[0].value;
            }
        }
    })

    return prefilledValues;
}

export {
    getPrefilledValues,
    getFilteredChoices
}