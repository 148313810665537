import { FaFilter, FaTimes } from "react-icons/fa";
import styled, { css } from "styled-components";
import { Button, IconButton } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import Modal from "../../../ui/components/modal/Modal";
import { FormContainer } from "../editableTable/styles/FormStyles";
import ColumnSelector from "../fields/components/logicFields/ColumnSelector";
import { conditionOperators } from "../fields/components/logicFields/logicUtils";
import OperatorSelector from "../fields/components/logicFields/OperatorSelector";
import renderValueField from "../fields/components/logicFields/renderValueFields";
import { fieldTypes } from "../fields/fieldUtils";
import { parseFilterItems } from "./filterUtils";

export const FilterButton = props => {
    const [showModal, setShowModal] = useState(false);

    return <>
        <IconButton size="small" onClick={() => setShowModal(true)}><FaFilter /></IconButton>

        <Modal show={showModal} close={() => setShowModal(false)}>
            <FormContainer>
                <h3>Filter</h3>
                <FilterBuilder {...props} />
            </FormContainer>
        </Modal>
    </>
}



const FilterBuilder = ({ columns, references, choices }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [filterItems, setFilterItems] = useState(parseFilterItems(searchParams));

    const updateFilterItem = (index, updatedProps) => {
        const updatedFilterItems = filterItems.map(item => ({ ...item }));
        updatedFilterItems[index] = { ...updatedFilterItems[index], ...updatedProps };
        setFilterItems(updatedFilterItems);
    }

    const addFilterItem = () => {
        const updatedFilterItems = filterItems.map(item => ({ ...item }));
        updatedFilterItems.push({ operator: conditionOperators.equal });
        setFilterItems(updatedFilterItems);
    }

    const removeFilterItem = (index) => {
        const updatedFilterItems = filterItems.filter((_, ix) => ix !== index);
        setFilterItems(updatedFilterItems);
    }

    const applyFilter = () => {
        const updatedSearchParams = {};
        filterItems.forEach(item => {
            if (item.column !== undefined && item.value !== undefined) {
                updatedSearchParams[item.column] = item.value;
            }
        });
        setSearchParams(updatedSearchParams);
    }

    return <>
        {filterItems.map((item, ix) => {
            return <>
                <FilterItem
                    key={ix}
                    item={item}
                    updateItem={(updatedProps) => updateFilterItem(ix, updatedProps)}
                    removeItem={() => removeFilterItem(ix)}
                    columns={columns}
                    references={references}
                    choices={choices}
                />
            </>
        })}
        <Button onClick={addFilterItem}>Add</Button>
        <Button primary onClick={applyFilter}>Apply</Button>
    </>
}

const Container = styled.div`
    display: flex;
    align-items: center;

    >div {
        margin-right: 12px;
        padding: 10px;
    }
`

const FilterItem = ({ item, updateItem, removeItem, columns, references, choices }) => {

    const selectColumn = (columnName) => {
        updateItem({ column: columnName });
    }

    const selectedColumn = columns.find(col => col.name === item.column);
    let fieldReferences = [];
    let fieldChoices = [];
    if (selectedColumn) {
        if (selectedColumn.type === fieldTypes.reference) {
            fieldReferences = references[selectedColumn.name]
        } else if (selectedColumn.type === fieldTypes.choice) {
            fieldChoices = choices[selectedColumn.name];
        }
    }


    return <Container>
        <ColumnSelector
            object={item}
            columns={columns}
            selectColumn={selectColumn}
        />
        {/* <OperatorSelector
            object={item}
            updateObject={updateItem}
            
        /> */}
        <div>
            =
        </div>

        {renderValueField(item, updateItem, columns, fieldReferences, fieldChoices)}

        <div>
            <IconButton onClick={removeItem}><FaTimes /></IconButton>
        </div>

    </Container>
}