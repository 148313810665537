
const getRemoteUpdatedPropsList = (remotePropsList, localPropsList, localUpdates, idField) => {
    const remoteUpdatedPropsList = [];
    remotePropsList.forEach(remoteProps => {
        const id = remoteProps[idField];
        const updatedKeys = localUpdates[id];
        const localProps = localPropsList?.find(localProps => localProps[idField] === id);
        let props = getRemoteUpdatedProps(remoteProps, localProps, updatedKeys);
        remoteUpdatedPropsList.push(props);
    });
    return remoteUpdatedPropsList;
}

const getRemoteUpdatedProps = (remoteProps, localProps, updatedKeys) => {
    let props;
    if (localProps && updatedKeys) {
        const updatedProps = {};
        updatedKeys.forEach(key => {
            updatedProps[key] = localProps[key];
        })
        props = { ...remoteProps, ...updatedProps };
    } else {
        props = { ...remoteProps };
    }
    return props;
}

const getChangedPropsList = (propsList, updatedKeys, idField) => {
    const changedPropsList = [];
    Object.keys(updatedKeys).forEach(id => {
        const props = propsList.find(object => object[idField] === id);
        if (props) {
            const changedProps = getChangedProps(props, updatedKeys[id], idField);
            changedPropsList.push(changedProps);
        }
    })
    return changedPropsList;
}

const getChangedProps = (props, keys, idField) => {
    const changedProps = { [idField]: props[idField] };
    keys.forEach(key => {
        changedProps[key] = props[key];
    })
    return changedProps;
}

const addToUpdatedKeys = (updatedKeys, id, updatedProps, removeKeys) => {
    Object.keys(updatedProps).forEach(key => {
        if (!updatedKeys[id]) {
            updatedKeys[id] = [key];
        } else if (!updatedKeys[id].includes(key)) {
            updatedKeys[id].push(key);
        }
    })

    if (removeKeys) {
        updatedKeys[id] = updatedKeys[id]?.filter(key => !removeKeys.includes(key));
        if (updatedKeys[id]?.length === 0) {
            delete updatedKeys[id];
        }
    }
    return updatedKeys;
}




export {
    getRemoteUpdatedPropsList,
    getRemoteUpdatedProps,
    getChangedPropsList,
    addToUpdatedKeys
}