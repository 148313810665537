import styled from "styled-components"
import theme from "../../ui/theme/theme"
import { Link } from "react-router-dom"

export const StyledList = styled.ul`
    list-style: none;
    margin: 10px 0;
    padding: 0;
`
export const ListItem = styled.li`
    display: grid;
    align-items: center;
    grid-template-rows: 24px;
    grid-template-columns: 1fr 40px;
    padding: 10px;

    border: 1px solid ${theme.colors.darkSpace15};
    border-radius: 4px;
    margin: 10px 0;

    &:hover {
        background-color: ${theme.colors.darkSpace10};
    }
`

export const StyledLink = styled(Link)`
    color: ${theme.colors.darkSpace80};
    font-size: ${theme.fontSize.medium};
`