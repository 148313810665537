import { cellStates } from "../../../editableTable/tableTypes";
import { getFieldComponent } from "../../fieldUtils";

const renderValueField = (object, updateObject, columns, references, choices) => {
    const column = columns.find(col => col.name === object.column);
    const Field = getFieldComponent(column?.type);
    const fieldColumn = { name: 'value' };
    const fieldObject = { value: object.value };

    return <Field
        column={fieldColumn}
        object={fieldObject}
        updateObject={updateObject}
        editable={true}
        cellState={cellStates.FORM}
        references={references}
        choices={choices}
    />
}

export default renderValueField;

