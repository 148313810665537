import { Button, Typography } from "@mui/material"
import UserContext from "./UserContext";
import { useContext, useState } from "react";
import { ModuleList } from "../ui/components/list/ListStyles";
import ImpersonateList from "./ImpersonateList";
import ReturnButton from "../ui/components/button/ReturnButton";
import styled from "styled-components";
import { getUser } from "../core/remote/user";
import { setAxiosDefaultAuth } from "../core/remote/server/server";


const views = {
    main: 1,
    impersonate: 2
}

const Flex = styled.div`
    display: flex;
    align-items: center;
`

const UserMenu = ({ }) => {
    const [view, setView] = useState(views.main);
    const userContext = useContext(UserContext);

    const impersonate = async (userId, clearAuthHeader) => {
        try {
            const userRes = await getUser(userId);
            userContext.setUser(userRes);
            if (clearAuthHeader) {
                setAxiosDefaultAuth(null);
            } else {
                setAxiosDefaultAuth(userId);
            }
        } catch (err) {

        }
    }

    const menuItems = [{
        name: 'profile',
        displayValue: 'Profile'
    }, {
        name: 'preferences',
        displayValue: 'Preferences'
    }, {
        name: 'impersonate',
        displayValue: 'Impersonate',
        hide: !userContext.isAdmin,
        onClick: () => setView(views.impersonate)
    }, {
        name: 'logout',
        displayValue: 'Log out'
    }];

    if (view === views.main) {
        return <>
            <Typography component="h1" variant="h5" fontWeight={'bold'}>User Menu</Typography>
            <div>
                <label>
                    Name
                </label>
                <Typography component="h3" variant="h6" >
                    {userContext.user.name}
                </Typography>
                {userContext.isImpersonating ? <>
                    <Button
                        variant="outlined"
                        onClick={() => impersonate(userContext.authUser.coreId, true)}
                    >
                        End impersonation
                    </Button>
                </> : <></>}
            </div>
            <div>
                <ModuleList>
                    {menuItems.map(item => {
                        if (item.hide) {
                            return <></>
                        }
                        return <li key={item.name}
                            onClick={item.onClick ? item.onClick : () => { }}
                        >
                            {item.displayValue}
                        </li>
                    }
                    )}
                </ModuleList>
            </div>
        </>
    } else if (view === views.impersonate) {
        return <>
            <Flex>
                <div><ReturnButton navigateBack={() => setView(views.main)} /></div>
                <Typography component="h1" variant="h5" fontWeight={'bold'}>Impersonate</Typography>
            </Flex>
            <ImpersonateList
                userContext={userContext}
                impersonate={impersonate}
            />
        </>
    }

}

export default UserMenu;