import styled from "styled-components";
import ModalField from "../ModalField";
import ColumnSelector from "./ColumnSelector";
import renderValueField from "./renderValueFields";
import useLogicBuilder from "./useLogicBuilder";
import OperatorSelector from "./OperatorSelector";
import { fieldTypes } from "../../fieldUtils";


const ConditionField = props => {
    return <ModalField {...props}>
        <ConditionBuilder {...props} />
    </ModalField>
}

export default ConditionField;

const ConditionBuilder = ({ object: record, updateObject: updateRecord }) => {
    const builderObject = record.condition || {};
    const updateBuilderObject = (updatedProps) => {
        updateRecord({ condition: { ...record.condition, ...updatedProps } })
    };
    const { object, updateObject, columns, references, choices, selectColumn } = useLogicBuilder({ table: record.table, object: builderObject, updateObject: updateBuilderObject })

    return <ConditionBuilderFields
        object={object}
        updateObject={updateObject}
        columns={columns}
        references={references}
        choices={choices}
        selectColumn={selectColumn}
    />
}

const Container = styled.div`
    display: flex;

    >div {
        margin-right: 12px;
        padding: 10px;
    }
`
const ConditionBuilderFields = ({
    object,
    updateObject,
    columns,
    references,
    choices,
    selectColumn,
}) => {
    const isReferenceColumn = columns?.find(col => object.column === col.name)?.type === fieldTypes.reference;

    return <Container>
        <div>
            <label>Field</label>
            {columns ? <>
                <ColumnSelector
                    object={object}
                    columns={columns}
                    selectColumn={selectColumn}
                />
            </> : <></>}
        </div>

        <div>
            <label>Operator</label>
            <OperatorSelector
                isReferenceColumn={isReferenceColumn}
                object={object}
                updateObject={updateObject}
             />
        </div>

        <div>
            <label>Value</label>
            {columns ? <>
                {renderValueField(object, updateObject, columns, references, choices)}
            </> : <></>}
        </div>
    </Container>
}
