import CellDropdown from "./components/CellDropdown"

const ChoiceField = ({ object, updateObject, choices, column, editable }) => {

    const onClick = (value) => {
        updateObject({ [column.name]: value});
    }
    const items = choices;
    const selectedItem = items.find(item => item.value === object[column.name]);

    return <CellDropdown
        selectedItem={selectedItem}
        items={items}
        onClick={onClick}
        cellControl={{}}
        excludeNone={true}
        disabled={!editable}
    />
}

export default ChoiceField;