import { Button, IconButton } from "@mui/material";
import { FaArrowRight, FaChevronDown, FaChevronRight, FaChevronUp, FaCog, FaColumns, FaTable } from "react-icons/fa";
import Modal from "../../../../ui/components/modal/Modal";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import theme from "../../../../ui/theme/theme";
import { FormContainer } from "../styles/FormStyles";
import { fieldTypes } from "../../fields/fieldUtils";
import { ModuleList } from "../../../../ui/components/list/ListStyles";

export const TableSettingsMenuButton = props => {
    const { apiPath } = useParams();
    const [showModal, setShowModal] = useState(false);

    if (apiPath === 'core') {
        return <></>
    }
    return <>
        <IconButton size="small" onClick={() => setShowModal(true)} >
            <FaCog />
        </IconButton>

        <Modal show={showModal} close={() => setShowModal(false)}>
            <FormContainer>
                <TableSettingsMenu {...props} />
            </FormContainer>
        </Modal>
    </>
}

const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const StyledLink = styled(Link)`
    text-decoration: none;
    color: ${theme.colors.darkSpace80};
    font-size: ${theme.fontSize.large};
`

const LinkContainer = styled.div`
    display: flex;
    align-items: center;
    svg {
        padding-right: 6px;
    }
`


const TableSettingsMenu = ({ columns }) => {
    const navigate = useNavigate();
    const { table } = useParams();

    const LinkListItem = ({ displayValue, path, Icon }) => {
        return <li onClick={() => navigate(path)}>
            <FlexContainer>
                <LinkContainer>
                    {Icon ? <>
                        <Icon />
                    </> : <></>}
                    <StyledLink>

                        {displayValue}
                    </StyledLink>
                </LinkContainer>
                <div><FaChevronRight /></div>
            </FlexContainer>
        </li>
    }

    const choiceColumns = columns.filter(column => column.type === fieldTypes.choice);

    return <div>
        <h3>Table settings</h3>
        <ModuleList>
            <LinkListItem displayValue={'Table'} path={`/core/table?name=${table}`} />
            <LinkListItem displayValue={'Columns'} path={`/core/column?table=${table}`} />
            <LinkListItem displayValue={'Client Rules'} path={`/core/clientRule?table=${table}`} />
            <LinkListItem displayValue={'Business rules'} path={`/core/businessRule?table=${table}`} />
            {choiceColumns.length ? <>
                <ChoiceColumns table={table} columns={choiceColumns} LinkListItem={LinkListItem} />
            </> : <></>}
        </ModuleList>
    </div>
}

const ChoiceColumns = ({ table, columns, LinkListItem }) => {
    const [expanded, setExpanded] = useState(true);

    return <div onClick={() => setExpanded(!expanded)}>
        <FlexContainer>
            <h4>Choice Columns</h4>
            <div> {expanded ? <FaChevronDown /> : <FaChevronUp />} </div>
        </FlexContainer>
        {expanded ? <>
            <ModuleList>
                {columns.map(column =>
                    <LinkListItem
                        key={column.name}
                        displayValue={column.displayName}
                        path={`/core/choice?table=${table}&column=${column.coreId}`}
                    />
                )}
            </ModuleList>
        </> : <></>}
    </div>
}