import { useEffect, useState } from "react"
import EditableTableContainer from "./editableTable/EditableTableContainer";
import { getApi } from "../remote/api";
import { Container } from "@mui/material";
import Breadcrumbs from "./filter/BreadCrumbs";
import useTableMetaData from "../hooks/useTableMetaData";
import { requiresRestart } from "./coreTableUtils";
import { wait } from "../../scripts/common";

const TableContainer = ({ apiPath, table, searchParams, hideBreadcrumbs }) => {

    const api = getApi(apiPath)
    const [records, setRecords] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const { columns, references, choices, clientRules } = useTableMetaData({ apiPath, table });

    useEffect(() => {
        getTableData();
    }, [table, apiPath, searchParams]);

    const getTableData = async () => {
        setErrorMessage(null);
        try {
            setRecords(null);
            const recRes = await api.getRecords(table, searchParams);
            setRecords(recRes);
        } catch (err) {
            if (err?.response?.status === 403) {
                setErrorMessage('You do not have access to this content');
            }
        }
    }

    const updateRecords = async () => {
        try {
            const recRes = await api.getRecords(table, searchParams);
            setRecords(recRes);
        } catch (err) {
            if (err?.response?.status === 403) {
                setErrorMessage('You do not have access to this content');
            }
        }
    }

    const saveObjects = async (objects) => {
        setErrorMessage(null);
        try {
            console.log('debug save', objects);
            const promises = [];
            objects.forEach(object => {
                promises.push(api.updateRecord(table, object));
            })
            await Promise.all(promises);
            await updateRecords();
        } catch (err) {
            if (err?.response?.status === 403) {
                setErrorMessage('You do not have access to update this object');
                await updateRecords();
            }
        }
    }

    const addObject = async (object) => {
        setErrorMessage(null);
        try {
            await api.addRecord(table, object);
            if (requiresRestart(table)) {
                await wait(2000);
            }
            await updateRecords();
        } catch (err) {
            if (err?.response?.status === 403) {
                setErrorMessage('You do not have access to create this object');
            } else if (err?.response?.status === 409) {
                setErrorMessage('Conflict - object already exists');
            }
        }
    }

    const deleteObject = async (object) => {
        setErrorMessage(null);
        try {
            await api.deleteRecord(table, object.coreId);
            await updateRecords();
        } catch (err) {
            if (err?.response?.status === 403) {
                setErrorMessage('You do not have access to delete this object');
            }
        }
    }

    return <Container maxWidth="lg" sx={{ backgroundColor: 'white', py: 10 }}>
        {columns && records && references && choices && clientRules ? <>
            {!hideBreadcrumbs ? <>
                <Breadcrumbs
                    columns={columns}
                    references={references}
                    choices={choices}
                />
            </> : <></>}
            <EditableTableContainer
                title={table}
                columns={columns}
                parentObjects={records}
                references={references}
                choices={choices}
                clientRules={clientRules}
                idField={'coreId'}
                saveObjects={saveObjects}
                addObject={addObject}
                deleteObject={deleteObject}
                searchParams={searchParams}
            />
        </> : <></>}
        {errorMessage ? <>
            {errorMessage}
        </> : <></>}


    </Container>

}

export default TableContainer;