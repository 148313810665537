import { useParams } from "react-router-dom";
import { getApi } from "../../core/remote/api";
import { useEffect, useState } from "react";
import { Container, Typography } from "@mui/material";
import TabSelector from "../../ui/components/TabSelector";
import TableList from "./TableList";
import { mergeSearchParams } from "../../scripts/common";
import AccessControlList from "./AccessControlList";


const tabKeys = {
    table: 1,
    ui: 2,
    accessControl: 3
}

const tabs = [{
    key: tabKeys.table,
    display: 'Tables'
}, {
    key: tabKeys.ui,
    display: 'UI'
}, {
    key: tabKeys.accessControl,
    display: 'Access Control'
}]

const ModuleEditor = ({ }) => {
    const { coreId } = useParams();
    const api = getApi('core');
    const [module, setModule] = useState();
    const [tables, setTables] = useState();
    const [roles, setRoles] = useState();
    const [accessRules, setAccessRules] = useState();
    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    useEffect(() => {
        getModuleData()
    }, [coreId]);

    const getModuleData = async () => {
        try {
            const moduleRes = await api.getRecord('module', coreId);
            const tableRes = await api.getRecords('table', mergeSearchParams({ module: coreId }));
            const roleRes = await api.getRecords('role', mergeSearchParams({ module: coreId }));
            //const accessRules = await api.get('role', mergeSearchParams({ module: coreId }));
            setModule(moduleRes);
            setTables(tableRes);
            setRoles(roleRes);
            console.log('debug get module', moduleRes, tableRes, roleRes);
        } catch (err) {

        }
    }

    return <Container maxWidth="md" sx={{ backgroundColor: 'white', py: 10 }}>
        {module ? <>
            Module editor
            <Typography component="h1" variant="h5">{module.name}</Typography>

            <div>
                <TabSelector
                    tabs={tabs}
                    selectedTab={selectedTab}
                    onClick={(tab) => setSelectedTab(tab)}
                />
            </div>

            {selectedTab.key === tabKeys.table && tables ? <>
                <TableList tables={tables} module={module} />
            </> : <></>}

            {selectedTab.key === tabKeys.ui ? <>
                UI
            </> : <></>}

            {selectedTab.key === tabKeys.accessControl && roles ? <>
                <AccessControlList roles={roles} />
            </> : <></>}
        </> : <></>}

    </Container>
}

export default ModuleEditor;