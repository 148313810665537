import { cellStates } from "../editableTable/tableTypes";
import theme from "../../../ui/theme/theme";
import styled, { css } from "styled-components";
import { editableColor } from "./Styles";

const Container = styled.div`
    padding: 4px 4px;
    ${props => props.active && css`
        background-color: ${theme.colors.darkSpace10};
    `}
`

const StyledTextArea = styled.textarea`
    outline: none;
    width: 100%;
    border: none;
    font-size: 1em;
    color: ${editableColor};
    background-color: transparent;
    resize: none
`

const MultilineTextField = ({ object, column, updateObject, cellState, editable, error, formLink }) => {
    const active = cellState === cellStates.ACTIVE || cellState === cellStates.FORM;
    return <Container active={active}>
        <StyledTextArea
            rows={active ? 4 : 1}
            value={object[column.name] || ''}
            onChange={(e) => updateObject({ [column.name]: e.target.value })}
        />
    </Container>

}

export default MultilineTextField;