import CellDropdown from "./components/CellDropdown"

const ReferenceField = ({ object, updateObject, references, column, editable, formLink }) => {

    const onClick = (value) => {
        updateObject({ [column.name]: value});
    }
    const selectedItem = references?.find(ref => ref.value === object[column.name]);

    return <CellDropdown
        selectedItem={selectedItem}
        items={references}
        onClick={onClick}
        cellControl={{}}
        excludeNone={true}
        disabled={!editable}
        formLink={formLink}
    />
}

export default ReferenceField;