import styled, { css } from "styled-components"
import theme from "../../../../ui/theme/theme"
import { cellStates } from "../tableTypes";

export const readOnlyColor = theme.colors.darkSpace60;

export const Table = styled.table`
    min-width: 100%;
    table-layout: auto;
    border-collapse: collapse;
    font-size: 14px;
    background-color: ${props => props.backgroundColor || 'white'};
    margin-bottom: 400px;

    th, td {
        border: 1px solid ${props => props.borderColor || theme.colors.darkSpace10};
        border-collapse: collapse;
    } 
`

export const HeaderRow = styled.tr`
    background-color: ${props => props.backgroundColor || theme.colors.darkSpace10};
    color: ${readOnlyColor};
    text-align: left;

    >th {
        padding: 4px;
        font-weight: bold;
    }
`

export const TableCell = styled.td`
    height: 30px;
    &:hover {
        cursor: pointer;
    }

    ${props => props.marked && css`
        outline: solid 1px ${theme.colors.darkSpace30};
    `}

    ${props => props.cellState === cellStates.ACTIVE  && css`
        outline: solid 2px ${theme.colors.blueMonday};
    `}

    ${props => props.updated && css`
        background-color: ${theme.colors.darkSpace20};
    `}
`