import { useEffect, useState } from "react";
import { getApi } from "../core/remote/api";
import styled, { css } from "styled-components";
import theme from "../ui/theme/theme";
import SearchField from "../ui/components/SearchField";

const api = getApi('api');

const Container = styled.div`
    margin: 12px 0;
`

const List = styled.ul`
    list-style: none;
    overflow-y: auto;
    max-height: 55vh;
    padding: 0;
`;

const ListItem = styled.li`
    padding: 5px 5px;
    display: grid;
    cursor: pointer;
    grid-template-columns: auto 1fr auto;
    gap: 10px;
    align-items: center;
    white-space: nowrap;

    &:hover {
        background-color: ${theme.colors.darkSpace30};
    }

    ${props => props.active && css`
        font-weight: bold;
    `}
`

const ImpersonateList = ({ userContext, impersonate }) => {
    const [users, setUsers] = useState();
    const [searchString, setSearchString] = useState('');

    const getUsers = async () => {
        try {
            const usersRes = await api.getRecords('sysUser');
            setUsers(usersRes);
        } catch (err) {

        }
    }

    useEffect(() => {
        getUsers()
    }, [])

    return <Container>
        <div>
            <SearchField value={searchString} onChange={(e) => setSearchString(e.target.value)} />
        </div>

        <List>
            {users?.filter(user => user.name.toLowerCase().includes(searchString.toLowerCase())).map(user => (
                <ListItem
                    key={user.coreId}
                    active={userContext.user.coreId === user.coreId}
                    onClick={() => impersonate(user.coreId)}
                >
                    <span>{user.name} {userContext.authUser.coreId === user.coreId ? '(me)' : ''}</span>
                </ListItem>
            )
            )}
        </List>
    </Container>

}

export default ImpersonateList;