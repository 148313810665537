const conditionOperators = {
    equal: 1,
    notEqual: 2,
    equalDymanic: 3
}

const actionTypes = {
    setValue: 1,
    hide: 2,
    disable: 3
}

const dynamicReferences = {
    currentUser: 1
}

const dynamicReferenceChoices = [{
    value: dynamicReferences.currentUser,
    displayValue: 'Me'
}]

export {
    conditionOperators,
    actionTypes,
    dynamicReferences,
    dynamicReferenceChoices
}
