import { Box, Button, Container, TextField, Typography } from "@mui/material"
import { useState } from "react";
import { login, signup } from "../core/remote/auth";
import theme from "../ui/theme/theme";
import styled from "styled-components";

const AuthView = ({ setUser }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isSignup, setIsSignup] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const submit = async () => {
        if (!isSignup) {
            loginUser();
        } else {
            signUpUser();
        }
    }

    const signUpUser = async () => {
        try {
            const user = await signup({ email, password });
            if (user) {
                setUser(user);
            }
        } catch (err) {
            displayError(err);
        }
    }

    const loginUser = async () => {
        try {
            const user = await login({ email, password });
            if (user) {
                setUser(user);
            }
        } catch (err) {
            displayError(err);
        }
    }

    const displayError = (err) => {
        if (err?.response?.status === 400 || err?.response?.status === 409) {
            setErrorMessage(err.response.data.toString());
        } else {
            setErrorMessage('An unknown error occured');
        }
    }

    return <>
        <Container maxWidth="lg" sx={{ backgroundColor: 'white', py: 10, display: 'flex', justifyContent: 'center' }}>

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                    textAlign: 'center'
                }}
                noValidate
                autoComplete="off"
            >

                <Typography component="h1" variant="h5" color={theme.colors.textGray} fontWeight={'bold'}>
                    {isSignup ? 'Register' : 'Login'}
                </Typography>
                <div>
                    <TextField
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        id="outlined-error"
                        label="Email"
                        defaultValue=""
                    />
                </div>
                <div>
                    <TextField
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        label="Password"
                        type="password"
                        autoComplete="current-password"
                    />
                </div>

                {errorMessage ? <>
                    <ErrorMessage>
                        {errorMessage}
                    </ErrorMessage>
                </> : <></>}

                <Box m={1}>
                    <div>
                        <Button sx={{
                            width: '100%'
                        }}
                            variant="contained"
                            onClick={submit}>
                            {isSignup ? 'Register' : 'Log in'}
                        </Button>
                    </div>
                    <div>
                        <Button sx={{
                            width: '100%'
                        }} onClick={() => setIsSignup(!isSignup)}>{isSignup ? 'Log in' : 'Register'}</Button>
                    </div>
                </Box>
            </Box>

        </Container>
    </>
}

export default AuthView;


const ErrorMessage = styled.div`
    color: ${theme.colors.raspberryRed};
    margin: 10px;
`