import ActionField from "./components/logicFields/ActionField";
import CheckboxField from "./CheckboxField";
import ChoiceField from "./ChoiceField";
import ConditionField from "./components/logicFields/ConditionField";
import DisplayField from "./DisplayField";
import ReferenceField from "./ReferenceField";
import StringField from "./StringField"
import NumberField from "./NumberField";
import TextField from "./TextField";
import DateTimeField, { DateField } from "./DateTimeField";

const fieldTypes = {
    string: 'string',
    text: 'text',
    number: 'number',
    reference: 'reference',
    choice: 'choice',
    boolean: 'boolean',
    condition: 'condition',
    action: 'action',
    date: 'date',
    datetime: 'datetime'
}

const getFieldComponent = (type) => {
    switch (type) {
        case fieldTypes.string:
            return StringField;
        case fieldTypes.text:
            return TextField;
        case fieldTypes.number:
            return NumberField;
        case fieldTypes.reference:
            return ReferenceField;
        case fieldTypes.choice:
            return ChoiceField;
        case fieldTypes.boolean:
            return CheckboxField;
        case fieldTypes.condition:
            return ConditionField;
        case fieldTypes.action:
            return ActionField;
        case fieldTypes.date:
            return DateField;
        case fieldTypes.datetime:
            return DateTimeField;
        default:
            return DisplayField;
    }
}

export {
    getFieldComponent,
    fieldTypes
}