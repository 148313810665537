import axios from "axios"
import { hostname } from "../../server/server"

export const getApi = (path) => {

    const getColumns = async (table) => {
        const res = await axios.get(`${hostname}/${path}/columns/${table}`);
        return res.data;
    }
    
    const getRecords = async (table, searchParams) => {
        const res = await axios.get(`${hostname}/${path}/records/${table}?${searchParams ? searchParams?.toString() : ''}`);
        return res.data;
    }

    const getRecord = async (table, coreId) => {
        const res = await axios.get(`${hostname}/${path}/record/${table}/${coreId}`);
        return res.data;
    }
    
    const getReferences = async (table, searchParams) => {
        const res = await axios.get(`${hostname}/${path}/references/${table}?${searchParams ? searchParams?.toString() : ''}`);
        return res.data;
    }
    
    const getChoices = async (table) => {
        const res = await axios.get(`${hostname}/${path}/choices/${table}`);
        return res.data;
    }

    const getClientRules = async (table) => {
        const res = await axios.get(`${hostname}/${path}/clientRules/${table}`);
        return res.data;
    }

    const getAccessRules = async (table) => {
        const res = await axios.get(`${hostname}/${path}/accessRules/${table}`);
        return res.data;
    }

    const getRelatedLists = async (table) => {
        const res = await axios.get(`${hostname}/${path}/relatedLists/${table}`);
        return res.data;
    }
    
    const updateRecord = async (table, record) => {
        const res = await axios.put(`${hostname}/${path}/${table}`, record);
        return res.data;
    }
    
    const addRecord = async (table, record) => {
        const res = await axios.post(`${hostname}/${path}/${table}`, record);
        return res.data;
    }
    
    const deleteRecord = async (table, coreId) => {
        const res = await axios.delete(`${hostname}/${path}/${table}?coreId=${coreId}`);
        return res.data;
    }
    
    return {
        getColumns,
        getRecords,
        getRecord,
        getReferences,
        getChoices,
        getClientRules,
        getAccessRules,
        getRelatedLists,
        updateRecord,
        addRecord,
        deleteRecord
    }
}
