import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useRef, useState } from 'react';
import { FieldLink, editableColor, readOnlyColor } from '../Styles';
import { FaChevronDown } from 'react-icons/fa';
import theme from '../../../../ui/theme/theme';
import styled, { css } from 'styled-components';
import SearchField from '../../../../ui/components/SearchField';

const DropdownButton = styled.button`
    padding: 8px 4px;
    border: 0;
    color: ${props => props.disabled ? readOnlyColor : editableColor};
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1em;
    background-color: transparent;

    :hover {
        cursor: pointer;
    }

    ${props => props.active && css`
        background-color: ${theme.colors.cardBackground};
    `}
`

const ButtonLabel = styled.span`
    margin-right: 12px;
`

const CellDropdown = props => {
    const [anchorEl, setAnchorEl] = useState(null);
    const buttonRef = useRef();
    const open = Boolean(anchorEl);

    const items = props.excludeNone ? props.items : [...props.items, { displayValue: '- None -', value: null }];
    const selectedItem = props.selectedItem || { displayValue: '- None -', value: null };

    const handleClick = () => {
        setAnchorEl(buttonRef.current);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (item) => {
        props.onClick(item.value);
        handleClose();
    }

    return <>
        <div style={{ height: '100%' }}>
            <DropdownButton
                style={{ height: '100%', fontSize: '1em', display: 'flex', justifyContent: 'space-between' }}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                ref={buttonRef}
            >
                <ButtonLabel>
                    {selectedItem ? <>
                        {props.formLink ? <>
                            <FieldLink to={props.formLink}>{selectedItem.displayValue}</FieldLink>
                        </> : <>
                            {selectedItem.displayValue}
                        </>}
                    </> : <>
                        -- None --
                    </>}
                </ButtonLabel>
                {!props.disabled ? <>
                    <FaChevronDown />
                </> : <></>}

            </DropdownButton>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                    style: {
                        maxHeight: 300,
                        width: '20ch',
                    },
                }}
            >
                
                {items.map(item => <MenuItem key={item.value} onClick={() => handleSelect(item)}>
                    {item.displayValue}
                </MenuItem>)}
            </Menu>
        </div>
    </>
}

export default CellDropdown;