import { Button, IconButton } from "@mui/material"
import { FaEllipsisH, FaPlus } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import { StyledLink, ListItem, StyledList } from "./Styles"

const TableList = ({ tables, module }) => {
    const navigate = useNavigate();

    return <><StyledList>
        {tables.map(table => <ListItem key={table.name}>
            <div>
                <StyledLink to={`/api/${table.name}`}>{table.displayName}</StyledLink>
            </div>
            <div><IconButton size="small"><FaEllipsisH /></IconButton></div>
        </ListItem>)}
    </StyledList>
        <Button startIcon={<FaPlus />}
            onClick={() => navigate(`/form/core/table/new?module=${module.coreId}`)}
            variant={'outlined'}
        >
            Add table
        </Button>
    </>
}

export default TableList;