import { Box, Card, CardActionArea, CardContent, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getApi } from "../core/remote/api";
import theme from "../ui/theme/theme";
import { FaChevronRight } from "react-icons/fa";
import { mergeSearchParams } from "../scripts/common";

const api = getApi('core');

const ModuleOverview = () => {
    const { coreId } = useParams();
    const navigate = useNavigate();
    const [module, setModule] = useState();
    const [moduleLinks, setModuleLinks] = useState();

    useEffect(() => {
        getData();
    }, [coreId]);

    const getData = async () => {
        try {
            const moduleRes = await api.getRecord('module', coreId);
            const moduleLinkRes = await api.getRecords('moduleLink', mergeSearchParams({ module: coreId }));
            setModule(moduleRes);
            setModuleLinks(moduleLinkRes);
        } catch (err) {

        }
    }

    const onModuleLinkClick = (moduleLink) => {
        try {
            let path = moduleLink.type === 1 ? `/api/${moduleLink.table}` : `/form/api/${moduleLink.table}/new`;
            navigate(path);
        } catch (err) {

        }
    
    }

    return <Container maxWidth="lg" sx={{ backgroundColor: 'white', py: 10 }}>
        {module ? <>
            <label style={{ fontSize: '12px', color: theme.colors.textGray }}>MODULE</label>
            <Typography component="h1" variant="h4" py={1}>{module.name}</Typography>
            <Typography variant="subtitle1">{module.description}</Typography>

            {moduleLinks ? <>
                <Box sx={{ width: 300, py: 2 }}>
                    {moduleLinks.map(moduleLink => <Card key={moduleLink.name} variant="outlined">
                        <CardActionArea onClick={() => onModuleLinkClick(moduleLink)}>
                            <div style={{ padding: "12px 10px", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div>
                                    <Typography>{moduleLink.name}</Typography>
                                </div>
                                <div><FaChevronRight /></div>
                            </div>
                        </CardActionArea>
                    </Card>)}
                </Box>
            </> : <></>}
        </> : <></>}
    </Container>
}

export default ModuleOverview;