import styled, {css} from 'styled-components';
import theme from '../theme/theme';


const defaultWidth = 300;

const InputField = styled.input`
    outline: none;
    padding: 12px 16px;
    font-size: ${theme.fontSize.medium};
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : 'white'};
    color: ${theme.colors.darkSpace90};
    width: ${props => props.width ? props.width : defaultWidth + 'px'};

    &::placeholder {
        color: ${theme.colors.textGray}80;
    }

    ${props => props.small && css`
        font-size: 14px;
        padding: 10px 12px;
    `}
`

export const InputFieldContainer = styled.div`
    margin: 16px 0;
`

export default InputField;
