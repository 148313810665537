import { Button, Card } from "@mui/material";
import { useState } from "react";
import { FaUser } from "react-icons/fa";
import { MenuButtonLabel } from "../navigation/NavigationStyles";
import Modal from "../ui/components/modal/Modal";
import UserMenu from "./UserMenu";
import { FormContainer } from "../core/table/editableTable/styles/FormStyles";

const UserMenuButton = ({ }) => {
    const [showModal, setShowModal] = useState(false);
    return <>
        <Button
            startIcon={<FaUser />}
            onClick={() => setShowModal(!showModal)}
            variant={'text'}
        >
            <MenuButtonLabel>
                User
            </MenuButtonLabel>
        </Button>

        <Modal show={showModal} close={() => setShowModal(false)}>
            <FormContainer>
                <UserMenu />
            </FormContainer>
        </Modal>
    </>
}

export default UserMenuButton;