import { useState } from "react";
import Modal from "../../../ui/components/modal/Modal";
import EditableTable from "./EditableTable";
import EditableTableHeader from "./EditableTableHeader";
import useEditableTable from "./hooks/useEditableTable";
import ModalFormContainer from "../../form/ModalFormContainer";

const EditableTableContainer = ({
    title,
    columns,
    references,
    choices,
    clientRules,
    parentObjects,
    idField,
    saveObjects,
    addObject,
    deleteObject,
    searchParams
}) => {
    const [objects, updateObjects, isEdited, saveHandler] = useEditableTable({ parentObjects, idField, saveObjects });
    const [showForm, setShowForm] = useState(false);
    const [formInitProps, setFormInitProps] = useState();

    const openForm = (initProps) => {
        if (initProps) {
            setFormInitProps(formInitProps);
        }
        setShowForm(true);
    }

    const hasData = () => {
        return objects && objects.length && objects[0][idField] !== undefined;
    }

    return <>
        <div style={{ overflowX: 'auto', marginBottom: 50 }}>
            <EditableTableHeader
                title={title}
                isEdited={isEdited}
                openForm={openForm}
                saveHandler={saveHandler}
                columns={columns}
            />

            {hasData() ? <>
                <EditableTable
                    objects={objects}
                    updateObjects={updateObjects}
                    columns={columns}
                    references={references}
                    choices={choices}
                    clientRules={clientRules}
                    isEdited={isEdited}
                    idField={idField}
                    deleteObject={deleteObject}
                />
            </> : <></>}


        </div>
        <Modal show={showForm} close={() => setShowForm(false)}>
            <ModalFormContainer
                columns={columns}
                onSubmit={async (object) => {
                    await addObject(object);
                    setShowForm(false);
                }}
                onCancel={
                    () => setShowForm(false)
                }
                record={{}}
                references={references}
                choices={choices}
                clientRules={clientRules}
                title={title}
                isNewRecord={true}
                searchParams={searchParams}
            />
        </Modal>
    </>
}

export default EditableTableContainer;