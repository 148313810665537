import styled from "styled-components"
import theme from "../../../../ui/theme/theme"

export const FormField = styled.div`
    margin: 20px 0;

    label {
        color: ${theme.colors.darkSpace50};
    }
`

export const FormInput = styled.div`
    input {
        height: 40px;
    }
`

export const FormContainer = styled.div`
    text-align: left;
    min-width: 300px;
    padding: 20px;
`