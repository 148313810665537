import { useSearchParams } from "react-router-dom";
import { getApi } from "../remote/api";
import { fieldTypes } from "../table/fields/fieldUtils";
import { useEffect, useState } from "react";

const useTableMetaData = ({ apiPath, table }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const api = getApi(apiPath)

    const [columns, setColumns] = useState();
    const [references, setReferences] = useState();
    const [choices, setChoices] = useState();
    const [clientRules, setClientRules] = useState();

    useEffect(() => {
        getTableData();
    }, [table, apiPath, searchParams]);

    const getReferenceData = async (colRes) => {
        if (colRes.some(col => col.type === fieldTypes.reference)) {
            const refRes = await api.getReferences(table);
            setReferences(refRes);
        } else {
            setReferences([]);
        }
    }

    const getChoiceData = async (colRes) => {
        if (colRes.some(col => col.type === fieldTypes.choice)) {
            const choiceRes = await api.getChoices(table);
            setChoices(choiceRes);
        } else {
            setChoices([]);
        }
    }

    const getTableData = async () => {
        try {
            setReferences(null);
            setChoices(null);
            setColumns(null);
            const colRes = await api.getColumns(table);
            const clientRuleRes = await api.getClientRules(table);

            await getReferenceData(colRes);
            await getChoiceData(colRes);

            setColumns(colRes);
            setClientRules(clientRuleRes);
        } catch (err) {

        }
    }

    return {
        columns,
        references,
        choices,
        clientRules
    }
}

export default useTableMetaData;