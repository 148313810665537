import { FaChevronRight, FaFilter } from "react-icons/fa";
import styled, { css } from "styled-components";
import theme from "../../../ui/theme/theme";
import { useSearchParams } from "react-router-dom";
import { capitalizeFirstLetter } from "../../../scripts/common";
import { addDisplayValues, parseFilterItems } from "./filterUtils";
import { FilterButton } from "./FilterBuilder";

const BreadcrumbsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: fill-available;
    overflow-x: auto;
    line-height: 1.1rem;
    box-sizing: border-box;
`

const CrumbContainer = styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;

    > svg {
        font-size: 1.1rem;
        max-width: 1.1rem;
        max-height: 1.1rem;
    }
`

const CrumbLabel = styled.label`
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 5px;
    color: ${theme.colors.textGray};
    stroke: ${theme.colors.textGray};
    white-space: nowrap;
    height: 100%;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    > svg {
        font-size: 1.1rem;
        max-width: 1.1rem;
        max-height: 1.1rem;
    }

    ${props => props.selected && css`
        color: ${theme.colors.greenReadable};
        stroke: ${theme.colors.greenReadable};
    `}
`

const Breadcrumbs = props => {
    const { columns, references, choices } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const filterItems = parseFilterItems(searchParams);
    addDisplayValues(filterItems, columns, references, choices);


    const selectFilterItem = (item, index) => {
        const updatedSearchParams = {};
        const updatedUrlFilter = filterItems.slice(0, index + 1);
        updatedUrlFilter.forEach(item => {
            updatedSearchParams[item.column] = item.value;
        });

        setSearchParams(updatedSearchParams);
    }

    const clearFilter = () => {
        setSearchParams({});
    }

    return <BreadcrumbsContainer>
        <FilterButton {...props} />
        <CrumbContainer key={'all'}>
            <CrumbLabel onClick={() => clearFilter()}>All</CrumbLabel>
            {filterItems.length ? <FaChevronRight /> : <></>}
        </CrumbContainer>

        {filterItems.map((item, index) => {
            const isSelectedElement = false;//index === props.selectedItemHierarchy?.length - 1;

            return <CrumbContainer key={index}>
                <CrumbLabel
                    selected={isSelectedElement}
                    onClick={() => selectFilterItem(item, index)}
                >
                    {capitalizeFirstLetter(item.column)}={item.displayValue}
                </CrumbLabel>
                {index < filterItems.length - 1 ? <FaChevronRight /> : <></>}
            </CrumbContainer>
        })}
    </BreadcrumbsContainer>
}

export default Breadcrumbs;

