import { Button, Typography } from "@mui/material"
import styled from "styled-components"
import { capitalizeFirstLetter } from "../../../scripts/common"
import { TableSettingsMenuButton } from "./settings/TableSettingsMenu"

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    align-items: center;
`

const ButtonContainer = styled.div`
    >button {
        margin-right: 6px;
    }
`

const EditableTableHeader = ({
    title,
    openForm,
    isEdited,
    saveHandler,
    columns
}) => {

    return <Container>
        <div>
            <Typography component="h1" variant="h5">{formatTitle(title)}</Typography>
        </div>
        <ButtonContainer>
            <TableSettingsMenuButton columns={columns} />
            <Button variant="contained" onClick={openForm}>Add</Button>
            <Button variant={!isEdited() ? 'disabled' : 'container'} onClick={saveHandler}>Save</Button>
        </ButtonContainer>
    </Container>
}

export default EditableTableHeader;

const formatTitle = (title) => {
    const capitalized = capitalizeFirstLetter(title);
    return capitalized ? capitalized + 's' : title;
}