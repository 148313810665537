import React from 'react';
import styled, { css } from 'styled-components';
import theme from '../../theme/theme';
import { Modal, SwipeableDrawer } from '@mui/material';
import { isMobile } from '../../../scripts/common';

const MyModal = props => {

    function handleClick(ev) {
        if (ev.target.id === 'modal-overlay') {
            ev.stopPropagation();
            props.close();
        }
    }

    if (isMobile()) {
        const anchor = 'bottom';
        return (
            <SwipeableDrawer
                anchor={anchor}
                open={props.show}
                onClose={props.close}
            >
                <ModalContent mobile={true}>
                {props.children}
                </ModalContent>
            </SwipeableDrawer>
        )
    } else {

        return (
            <Modal
                open={props.show}
                onClose={props.close}
            >
                <ModalContent>
                    {props.children}
                </ModalContent>
            </Modal>
        )
    }
}

export default MyModal;

export const ModalContent = styled.div`
    
    text-align: center;
    overflow: auto;
    >button {
        margin: 6px;
    }
    background-color: ${props => props.backgroundColor || 'white'};
    color: black;
    border-radius: 2px;

    ${props => props.mobile ? css`
        height: 90vh;
    ` : css`
        position: absolute;
        max-height: 70vh;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    `}
`

export const FixedWidthModalContent = styled.div`

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        width: 100%;
    }
    @media only screen and (min-width: ${theme.screenSizes.medium}px) {
        width: ${props => props.width || '40em'};
    }
`