import { Button, Typography } from "@mui/material";
import { fieldTypes, getFieldComponent } from "../fields/fieldUtils";
import { FormContainer, FormField, FormInput } from "./styles/FormStyles";
import { cellStates } from "./tableTypes";
import { capitalizeFirstLetter, parseSearchParams } from "../../../scripts/common";
import { useParams, useSearchParams } from "react-router-dom";
import { applyClientRules } from "../fields/components/logicFields/clientRuleUtils";
import useEditableForm from "./hooks/useEditableForm";
import { getFilteredChoices } from "../../form/formUtils";
import { loadingStates } from "../../../common/types";
import { useState } from "react";
import LoadingSpinner from "../../../ui/components/LoadingSpinner";

const EditableForm = ({
    record,
    columns,
    references,
    clientRules,
    choices,
    onSubmit,
    onCancel,
    title,
    isNewRecord
}) => {
    const { apiPath } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const urlFilter = parseSearchParams(searchParams);
    const [object, updateObject, isEdited, saveHandler] = useEditableForm({
        parentObject: record,
        saveObject: onSubmit
    });
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);

    const { updatedRecord, columnProps } = applyClientRules(clientRules, object, columns);
    if (Object.keys(updatedRecord).length) {
        updateObject(updatedRecord);
    }

    const submitHandler = async (object) => {
        const timeout = setTimeout(() => {
            setLoadingState(loadingStates.LOADING);
        }, 250)
        try {

            if (isNewRecord) {
                await onSubmit(object)
            } else {
                await saveHandler()
            }
            clearTimeout(timeout)
            setLoadingState(loadingStates.SUCCESS);
        } catch (err) {
            clearTimeout(timeout)
            setLoadingState(loadingStates.FAILED);
        }
    }

    return <>
        <FormContainer>
            {title ? <>
                <Typography component="h1" variant="h5"> {isNewRecord && 'Add'} {title}</Typography>
            </> : <></>}
            {object ? <>
                {columns.map((column, ix) => {
                    const Field = getFieldComponent(column.type);
                    let fieldReferences = [];
                    if (column.type === fieldTypes.reference) {
                        fieldReferences = getFilteredChoices(references[column.referenceTable], column, urlFilter)
                    }
                    let fieldChoices = [];
                    if (column.type === fieldTypes.choice) {
                        fieldChoices = getFilteredChoices(choices[column.name], column, urlFilter)
                    }

                    let formLink;
                    if (column.type === fieldTypes.reference) {
                        const referenceApiPath = column.isCoreReference ? 'core' : apiPath;
                        formLink = `/form/${referenceApiPath}/${column.referenceTable}/${object[column.name]}`;
                    }

                    if (!columnProps[column.name]?.hide) {
                        return <FormField key={ix}>
                            <div>
                                <label>{capitalizeFirstLetter(column.displayName || column.name)}</label>
                            </div>
                            <FormInput>
                                <Field
                                    column={column}
                                    object={object}
                                    updateObject={updateObject}
                                    editable={!columnProps[column.name]?.disable && (isNewRecord || !object.preventUpdate)}
                                    cellState={cellStates.FORM}
                                    references={fieldReferences}
                                    choices={fieldChoices}
                                    formLink={formLink}
                                />
                            </FormInput>
                        </FormField>
                    } else {
                        return <></>
                    }
                })}
                <div>
                    {isNewRecord || isEdited() ? <>
                        <Button variant="contained" onClick={() => submitHandler(object)} disabled={loadingState === loadingStates.LOADING}>
                            {isNewRecord ? 'Add' : 'Save'}
                        </Button>
                    </> : <></>}

                    {onCancel ? <>
                        <Button variant="outlined" onClick={onCancel}>Cancel</Button>
                    </> : <></>}

                    {loadingState === loadingStates.LOADING ? <>
                        <div>
                            <LoadingSpinner />
                        </div>
                    </> : <></>}
                </div>
            </> : <></>}
        </FormContainer>

    </>
}

export default EditableForm;

