import { useEffect, useState } from "react"
import { getApi } from "../remote/api";
import { mergeSearchParams } from "../../scripts/common";
import TableContainer from "../table/TableContainer";

const RelatedListContainer = ({ table, coreId, apiPath }) => {
    const [relatedLists, setRelatedLists] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const api = getApi(apiPath);

    useEffect(() => {
        getRelatedLists();
    }, [table])

    const getRelatedLists = async () => {
        try {
            const relatedListRes = await api.getRelatedLists(table);
            setRelatedLists(relatedListRes);
        } catch (err) {
            setErrorMessage('Error - could not get related lists');
        }
    }
    
    return <>
        {relatedLists && relatedLists.length ? <>

            <TableContainer
                table={relatedLists[0].referenceTable}
                apiPath={apiPath}
                searchParams={mergeSearchParams({ [table]: coreId, _orderBy: relatedLists[0].orderBy })}
                hideBreadcrumbs={true}
            />

        </> : <></>}
    </>
}

export default RelatedListContainer;