import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getApi } from "../../../../remote/api";
import { fieldTypes } from "../../fieldUtils";
import { conditionOperators, dynamicReferenceChoices } from "./logicUtils";

const useLogicBuilder = ({ table, object, updateObject }) => {
    const api = getApi('api')
    const [searchParams, setSearchParams] = useSearchParams();

    const [columns, setColumns] = useState();
    const [references, setReferences] = useState([]);
    const [choices, setChoices] = useState([]);

    const getReferencesForColumn = async (col) => {
        try {
            const refRes = await api.getReferences(table); // todo: filter request with column
            console.log('debug get references', refRes[col.referenceTable]);
            setReferences(refRes[col.referenceTable]);
        } catch (err) {

        }
    }

    const getDynamicReferences = () => {
        setReferences(dynamicReferenceChoices);
    }

    const getChoicesForColumn = async (col) => {
        try {
            const choiceRes = await api.getChoices(table); // todo: filter request with column
            setChoices(choiceRes[col.name]);
        } catch (err) {

        }
    }

    const getData = async () => {
        try {
            const colRes = await api.getColumns(table);
            if (object.column) {
                await updateValueOptions(object.column, colRes);
            }
            setColumns(colRes);
        } catch (err) {
            console.log('getdata debug', err);
        }
    }

    const updateValueOptions = async (columnName, currentColumns) => {
        currentColumns = currentColumns || columns;
        const selectColumn = currentColumns.find(col => col.name === columnName);
        if (object.operator === conditionOperators.equalDymanic) {
            getDynamicReferences();
        } else if (selectColumn.type === fieldTypes.reference) {
            await getReferencesForColumn(selectColumn);
        } else if (selectColumn.type === fieldTypes.choice) {
            await getChoicesForColumn(selectColumn);
        } else {

        }
    }

    const selectColumn = (columnName) => {
        const currentColumn = object.column;
        if (columnName != currentColumn) {
            updateObject({column: columnName, value: null });
            updateValueOptions(columnName);
        }
    }

    useEffect(() => {
        getData();
    }, [table, object.operator, searchParams])

    return {
        object,
        updateObject,
        columns, 
        references, 
        choices, 
        selectColumn
    }
}

export default useLogicBuilder;