import styled, { css } from "styled-components";
import { isMobile } from "../scripts/common";
import theme from "../ui/theme/theme";
import { useEffect, useState } from "react";
import { Button, Drawer, IconButton, SwipeableDrawer } from "@mui/material";
import { FaCog, FaHome, FaList } from "react-icons/fa";
import SearchField from "../ui/components/SearchField";
import { loadingStates } from "../common/types";
import TabSelector from "./SideMenuTabSelector";
import { Link, useNavigate } from "react-router-dom";
import { getApi } from "../core/remote/api";
import { logout } from "../core/remote/auth";
import { FiLogOut, FiSidebar } from "react-icons/fi";
import { SideBarButtonContainer } from "./NavigationStyles";
import UserMenuButton from "../user/UserMenuButton";

const Container = styled.div`
    background-color: ${theme.colors.darkSpace90};
    flex-direction: row-reverse;
    color: ${theme.colors.norwegianWood}; 

    height: 100vh;
    
    button {
        color: white;
        border-color: white;
    }
`

const BottomContainer = styled.div`
    position: absolute;
    bottom: 0px;
    border-top: 1px solid ${theme.colors.darkSpace70};
    width: 100%;
    padding: 12px 0;
`

const Flex = styled.div`
    display: flex;
    align-items: center;
`

const SearchFieldContainer = styled.div`
    margin: 20px 0;
    width: 100%;
`

const ModuleList = styled.ul`
    list-style: none;
    margin: 10px 0;
    padding: 0;
`

const ListItem = styled.li`
    padding: 12px 16px;
    ${props => props.indented && css`
        >a {
            margin-left: 10px;
        }
    `}

    &:hover {
        background-color: ${theme.colors.darkSpace80};
        cursor: pointer;
    }
`

const StyledLink = styled(Link)`
    text-decoration: none;
    color: ${theme.colors.darkSpace20};
    font-size: ${theme.fontSize.large};
`

const tabKeys = {
    crud: 1,
    core: 2
}

const tabs = [{
    key: tabKeys.crud,
    display: 'Modules'
}, {
    key: tabKeys.core,
    display: 'Core'
}];

const api = getApi('core');

const SideMenu = ({ show: expanded, setShow: setExpanded }) => {
    const navigate = useNavigate();
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);
    const [searchString, setSearchString] = useState();
    const [modules, setModules] = useState();
    const [tables, setTables] = useState();
    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    const getData = async () => {
        try {
            setLoadingState(loadingStates.LOADING);
            const moduleRes = await api.getRecords('module');
            const recRes = await api.getRecords('table');
            setModules(moduleRes);
            setTables(recRes);
            setLoadingState(loadingStates.SUCCESS);
        } catch (err) {

        }
    }

    useEffect(() => {
        getData()
    }, []);

    const onLinkClick = (path) => {
        navigate(path);
        if (isMobile()) {
            setExpanded(false);
        }
    }

    const LinkListItem = ({ displayValue, path, indented }) => {
        return <ListItem indented={indented} onClick={() => onLinkClick(path)}><StyledLink>{displayValue}</StyledLink></ListItem>
    }

    const SideBarButton = () => {
        return <IconButton size="medium" onClick={() => setExpanded(!expanded)} >
            <FiSidebar />
        </IconButton>
    }

    return <>
        <Container>
            <Flex>
                <SearchFieldContainer>
                    <SearchField
                        value={searchString}
                        onChange={(e) => setSearchString(e.target.value)}
                        width={'100%'}
                        backgroundColor={'transparent'}
                    />
                </SearchFieldContainer>
                <div style={{ margin: '10px' }}>
                    <SideBarButton />
                </div>
            </Flex>
            <TabSelector
                tabs={tabs}
                selectedTab={selectedTab}
                onClick={(tab) => setSelectedTab(tab)}
            />
            {loadingState === loadingStates.SUCCESS ? <>
                <div>
                    <ModuleList>
                        {selectedTab.key === tabKeys.crud ? <>

                            {modules.map(module => {
                                const moduleTables = tables.filter(table => module.coreId === table.module);
                                return <ModuleItem
                                    module={module}
                                    tables={moduleTables}
                                    LinkListItem={LinkListItem}
                                    apiPath={'api'}
                                />
                            })}
                        </> : <></>}


                        {selectedTab.key === tabKeys.core ? <>
                            <LinkListItem key={'module'} path={`/core/module`} displayValue={'Modules'} />
                            <LinkListItem key={'moduleLink'} path={`/core/moduleLink`} displayValue={'Module Links'} />
                            <LinkListItem key={'table'} path={`/core/table`} displayValue={'Tables'} />
                            <LinkListItem key={'column'} path={`/core/column`} displayValue={'Columns'} />
                            <LinkListItem key={'choice'} path={`/core/choice`} displayValue={'Choices'} />
                            <LinkListItem key={'clientRule'} path={`/core/clientRule`} displayValue={'Client rules'} />
                            <LinkListItem key={'businessRule'} path={`/core/businessRule`} displayValue={'Business rules'} />
                            <ModuleItem
                                module={{ name: 'Access Control' }}
                                tables={accessControlTables}
                                LinkListItem={LinkListItem}
                                apiPath={'core'}
                            />
                            <ModuleItem
                                module={{ name: 'View' }}
                                tables={viewTables}
                                LinkListItem={LinkListItem}
                                apiPath={'core'}
                                setSideMenuExpanded={setExpanded}
                            />
                        </> : <></>}

                    </ModuleList>
                </div>
            </> : <></>}


            <BottomContainer>
                <div>
                    <Button
                        startIcon={<FaHome />}
                        onClick={() => navigate('/')}
                        variant={'text'}
                    >
                        Home
                    </Button>
                </div>
                <div>
                    <UserMenuButton />
                </div>
                <Button
                    startIcon={<FiLogOut />}
                    onClick={logout}
                    variant={'text'}
                >
                    Log out
                </Button>

            </BottomContainer>
        </Container>
    </>
}

export default SideMenu;

const StyledModuleItem = styled(ListItem)`
    ${props => props.expanded && css`
        background-color: ${theme.colors.darkSpace70};
    `}
    font-size: ${theme.fontSize.large};
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
        font-size: ${theme.fontSize.small};
        color: ${theme.colors.darkSpace20};
    }
`

const accessControlTables = [{
    name: 'accessRule',
    displayName: 'Access Rule'
}, {
    name: 'group',
    displayName: 'Group'
}, {
    name: 'role',
    displayName: 'Role'
}, {
    name: 'groupRole',
    displayName: 'Group Role'
}];

const viewTables = [{
    name: 'relatedList',
    displayName: 'Related List'
}];

const ModuleItem = ({ module, tables, LinkListItem, apiPath, setSideMenuExpanded }) => {
    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate();

    const onLinkClick = (path, e) => {
        e.stopPropagation();
        navigate(path);
        if (isMobile()) {
            setSideMenuExpanded(false);
        }
    }

    return <>
        <StyledModuleItem expanded={expanded} onClick={() => setExpanded(!expanded)}>
            <div>{module.name}</div>
            {/* TODO: Check if user is admin */}
            <div>
                <IconButton size="small" onClick={(e) => onLinkClick(`/moduleEditor/${module.coreId}`, e)}><FaCog /></IconButton>
            </div>
        </StyledModuleItem>
        {expanded ? <>
            {tables.map(table => {
                return <LinkListItem
                    indented
                    key={table.name}
                    path={`/${apiPath}/${table.name}`}
                    displayValue={table.displayName}
                />
            })}
        </> : <></>}
    </>
}