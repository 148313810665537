import CellDropdown from "../CellDropdown";
import { conditionOperators } from "./logicUtils";

const OperatorSelector = ({object, updateObject, isReferenceColumn}) => {
    const operatorItems = [{
        value: conditionOperators.equal,
        displayValue: '='
    }, {
        value: conditionOperators.notEqual,
        displayValue: '≠'
    }];

    if (isReferenceColumn) {
        operatorItems.push({
            value: conditionOperators.equalDymanic,
            displayValue: '= (dynamic)'
        })
    }

    const selectedItem = operatorItems.find(item => item.value === object.operator);

    return <CellDropdown
        items={operatorItems}
        selectedItem={selectedItem}
        onClick={(value) => updateObject({ operator: value })}
        cellControl={{}}
        excludeNone={true}
    />
}

export default OperatorSelector;