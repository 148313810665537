import { createTheme, responsiveFontSizes } from "@mui/material";
import theme from "./theme";

let muiTheme = createTheme({
    palette: {
        primary: {
            main: '#10a37f'
        },
        secondary: {
            main: theme.colors.mellowYellow
        }
    },
    typography: {
        fontSize: 12
    }
});
muiTheme = responsiveFontSizes(muiTheme);

export default muiTheme;