import styled, { css } from 'styled-components';
import InputField from '../../../ui/components/InputField';
import { cellStates, requiredStates } from '../editableTable/tableTypes';
import theme from '../../../ui/theme/theme';
import { Link } from 'react-router-dom';

export const editableColor = theme.colors.darkSpace70;
export const readOnlyColor = theme.colors.darkSpace50;

export const StyledInputField = styled(InputField)`
    font-size: 1em;
    width: 100%;
    height: 100%;
    color: ${editableColor};
    padding: 0;
    background-color: transparent;

    &:hover {
        cursor: default;
    }
`

export const DisplayField = styled.span`
    color: ${readOnlyColor};
    font-size: 1em;
    padding-left: 4px;

    ${props => !props.readOnly && css`
        &:hover {
            cursor: pointer;
        }
    `}
`

export const Disabled = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    color: ${readOnlyColor};
`

export const Indicator = styled.div`
    height: 12px;
    width: 12px;
    border-radius: 2px;
    display: inline-block;
    
    ${props => props.color && css`
        background-color: ${props.color};
    `}
`

export const FieldLink = styled(Link)`
    color: ${theme.colors.darkSpace80};
`