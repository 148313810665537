const theme = {
    colors: {
        greenEnergy: '#aadb23',
        greenReadable: '#92b500',
        darkSpace: '#0C101C',
        darkSpace90: '#141925',
        darkSpace80: '#222731',
        darkSpace70: '#353A44',
        darkSpace60: '#52555E',
        darkSpace50: '#72757D',
        darkSpace40: '#94969E',
        darkSpace30: '#AFB2B8',
        darkSpace20: '#C3C7CC',
        darkSpace15: '#D7DCE0',
        darkSpace10: '#E4E9ED',
        darkSpace05: '#F4F8FA',
        whiteSpace: '#FFFFFF',
        mellowYellow: '#ffec9c',
        raspberryRed: '#e53e5e',
        norwegianWood: '#F4F2E9',

        blueSteel: '#f3f3f8',
        mediumRare: '#FB8D75',
        greenMint: '#68EDCB',
        blueMonday: '#006E72',
        blueOcean: '#263b5d',
        purplePleasure: '#995598',
        
        textGray: '#353A44',
        grayBorder: '#333744',
        grayHover: '#4D5161',
        dropdownGray: '#21242e',    

        // Infographic colors
        crystalBlue: '#4bc0c0', // Decibel
        yellowFever: '#ffc02c', // System volume
        pinky: '#ff2cab', // Noise

        sliderBackground: '#2c2d2f',
    },
    gradient: {
        gradientLight: '#004361',
        gradientDark: '#0F182D'
    },
    screenSizes: {
        large: 1080,
        medium: 768,
        small: 450
    },
    fontSize: {
        tiny: '0.8rem',
        small: '0.9rem',
        medium: '1rem',
        large: '1.1rem',
        larger: '1.2rem'
    }
}

export default theme;