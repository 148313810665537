import { Button, Container, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getApi } from "../../core/remote/api";
import { filterObject, mergeSearchParams } from "../../scripts/common";
import { actionTypes, objectTypes } from "./accessRuleTypes";
import { HeaderRow, Table, TableCell } from "../../core/table/editableTable/styles/TableStyles";
import Checkbox from "../../ui/components/Checkbox";
import styled from "styled-components";

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
`

const api = getApi('core');

const RoleEditor = ({ }) => {
    const { coreId } = useParams();
    const [role, setRole] = useState();
    const accessRules = useRef();
    const savedTableAccess = useRef();
    const [tableAccess, setTableAccess] = useState();

    useEffect(() => {
        getRoleData();
    }, [coreId]);

    const getRoleData = async () => {
        try {
            const roleRes = await api.getRecord('role', coreId);
            const tableRes = await api.getRecords('table', mergeSearchParams({ module: roleRes.module }));
            const accessRuleRes = await api.getRecords('accessRule', mergeSearchParams({ module: roleRes.module }));
            const tableAccessRes = getTableAccess(roleRes, tableRes, accessRuleRes);
            setRole(roleRes);
            savedTableAccess.current = tableAccessRes;
            accessRules.current = accessRuleRes;
            setTableAccess(tableAccessRes);
        } catch (err) {

        }
    }

    const getTableAccess = (role, tables, accessRules) => {
        return tables.map(table => {
            let tableRule = accessRules.find(accessRule => accessRule.table === table.name && accessRule.role === role.coreId);
            if (!tableRule) {
                tableRule = { create: 0, read: 0, update: 0, delete: 0 };
            }
            return {
                tableName: table.name,
                tableDisplayName: table.displayName,
                ...tableRule
            }
        })
    }

    const updateTableAccess = (tableName, actionType, toState) => {
        const updatedTableAccess = tableAccess.map(e => ({ ...e }));
        const element = updatedTableAccess.find(e => e.tableName === tableName);
        if (element) {
            element[actionType] = toState;
        }
        setTableAccess(updatedTableAccess);
    }
    
    const ruleIsChanged = (currentRule, updatedRule) => {
        return Object.keys(actionTypes).some(key => currentRule[key] !== updatedRule[key]);
    }

    const hasRules = (rule) => {
        return Object.keys(actionTypes).some(key => rule[key]);
    }

    const saveHandler = async () => {
        try {
            for (let i = 0; i < tableAccess.length; i++) {
                const tableRule = tableAccess[i];
                const savedTableRule = accessRules.current.find(accessRule => accessRule.table === tableRule.tableName && accessRule.role === role.coreId);
                if (!savedTableRule) {
                    if (hasRules(tableRule)) {
                        await api.addRecord('accessRule', {
                            table: tableRule.tableName,
                            role: role.coreId,
                            name: `${tableRule.tableDisplayName}`,
                            objectType: objectTypes.record,
                            ...tableRule
                        });
                    }
                } else if (ruleIsChanged(savedTableRule, tableRule)) {
                    await api.updateRecord('accessRule', filterObject(tableRule, ['coreId', ...Object.keys(actionTypes)]));
                }
            }

            await getRoleData();
        } catch (err) {
            console.log('debug err', err);
        }
    }

    const getCheckBoxField = (tableAccess, actionType) => {
        const checkedState = tableAccess[actionType];
        return <td><Checkbox checked={checkedState} onChange={() => updateTableAccess(tableAccess.tableName, actionType, !checkedState)} /></td>
    }

    return <Container maxWidth="md" sx={{ backgroundColor: 'white', py: 10 }}>
        Role editor
        {role && tableAccess ? <>
            <Typography component="h1" variant="h5">{role.name}</Typography>

            <Flex>
                <Typography component="h3" variant="h6">Table access</Typography>
                <div>
                    <Button variant={false ? 'disabled' : 'container'} onClick={saveHandler}>Save</Button>
                </div>
            </Flex>
            <Table>
                <thead>
                    <HeaderRow>
                        <th>Table</th>
                        <th>Create</th>
                        <th>Read</th>
                        <th>Update</th>
                        <th>Delete</th>
                    </HeaderRow>
                </thead>

                <tbody>
                    {tableAccess.map((tableAccess, ix) => <tr key={ix}>
                        <td>{tableAccess.tableDisplayName}</td>
                        {getCheckBoxField(tableAccess, 'create')}
                        {getCheckBoxField(tableAccess, 'read')}
                        {getCheckBoxField(tableAccess, 'update')}
                        {getCheckBoxField(tableAccess, 'delete')}
                    </tr>)}
                </tbody>

            </Table>

        </> : <></>}



    </Container>

}
export default RoleEditor;

