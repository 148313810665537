const objectTypes = {
    table: 2,
    column: 3,
    record: 4
}

const actionTypes = {
    read: 1,
    create: 2,
    update: 3,
    delete: 4
}

export {
    objectTypes,
    actionTypes
}