import { Box, Drawer, IconButton, SwipeableDrawer } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useState } from 'react';
import SideMenu from '../../navigation/SideMenu';
import { SideBarButtonContainer } from '../../navigation/NavigationStyles';
import { FiSidebar } from 'react-icons/fi';
import { isMobile } from '../../scripts/common';

const drawerWidth = 350;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const MainContainer = (props) => {
    const theme = useTheme();
    const mobile = isMobile();
    const [showSideMenu, setShowSideMenu] = useState(!mobile);

    const SideBarButton = () => {
        return <IconButton size="medium" onClick={() => setShowSideMenu(!showSideMenu)} >
            <FiSidebar />
        </IconButton>
    }

    const SideMenuDrawer = () => {
        if (!mobile) {
            return <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    }
                }}
                variant="persistent"
                anchor="left"
                open={showSideMenu}
            >
                <SideMenu
                    show={showSideMenu}
                    setShow={setShowSideMenu}
                />
            </Drawer>
        } else {
            return <SwipeableDrawer
                anchor={'left'}
                open={showSideMenu}
                onClose={() => setShowSideMenu(false)}
            >
                <SideMenu
                    show={showSideMenu}
                    setShow={setShowSideMenu}
                />
            </SwipeableDrawer>

        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            {!showSideMenu ? <>
                <SideBarButtonContainer>
                    <SideBarButton />
                </SideBarButtonContainer>
            </> : <></>}
            <SideMenuDrawer />
            {mobile ? <>
                <div>
                    {props.children}
                </div>
            </> : <>
                <Main open={showSideMenu}>
                    {props.children}
                </Main>
            </>}
        </Box>
    )
}

export default MainContainer;