import { useState, useEffect, useRef } from 'react';
import { propHasChanged, updatePropsList } from '../../../../scripts/state/stateUtils';
import { addToUpdatedKeys, getChangedPropsList, getRemoteUpdatedPropsList } from "../tableUtils";

const useEditableTable = ({ parentObjects, idField, saveObjects }) => {
    const [updatedObjects, setUpdatedObjects] = useState();
    const updatedKeys = useRef({});

    useEffect(() => {
        handleRemoteUpdate(parentObjects);
    }, [parentObjects])

    useEffect(() => {
        setUpdatedKeys({});
    }, [idField])

    const setUpdatedKeys = (keys) => {
        updatedKeys.current = keys;
    }

    const handleRemoteUpdate = (remoteObjects) => {
        const remoteUpdatedObjects = getRemoteUpdatedPropsList(remoteObjects, updatedObjects, updatedKeys.current, idField);
        setUpdatedObjects(remoteUpdatedObjects);
    }

    const updateObjects = (id, updatedProps) => {
        const parentObject = parentObjects.find(parent => parent[idField] === id);
        const unchangedKeys = [];
        Object.keys(updatedProps).forEach(key => {
            if (!propHasChanged(parentObject[key], updatedProps[key])) {
                unchangedKeys.push(key);
            }
        });
        addToUpdatedKeys(updatedKeys.current, id, updatedProps, unchangedKeys);
        setUpdatedObjects(updatePropsList([...updatedObjects], id, updatedProps, idField))
    }

    const isEdited = (id, key) => {
        if (id && key) {
            return updatedKeys.current[id]?.includes(key);
        } else if (id) {
            return updatedKeys.current[id]?.length;
        } else {
            return Object.keys(updatedKeys.current).length;
        }
    }

    const getChangedObjects = () => {
        return getChangedPropsList(updatedObjects, updatedKeys.current, idField);
    }

    const saveHandler = async () => {
        try {
            const changedObjects = getChangedObjects();
            await saveObjects(changedObjects);
            setUpdatedKeys({});
        } catch (err) {

        }
    }

    return [updatedObjects, updateObjects, isEdited, saveHandler];
}

export default useEditableTable;