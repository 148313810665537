import { Box, Card, CardActionArea, CardContent, Container, TextField, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react"
import { getApi } from "../core/remote/api";
import { useNavigate } from "react-router-dom";
import SearchField from "../ui/components/SearchField";

const api = getApi('core');

const ModuleList = () => {
    const [modules, setModules] = useState();
    const [searchString, setSearchString] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        getModules();
    }, []);

    const getModules = async () => {
        try {
            const moduleRes = await api.getRecords('module');
            setModules(moduleRes);
        } catch (err) {

        }
    }

    return <Container maxWidth="lg" sx={{ backgroundColor: 'white', py: 10 }}>
        <Box py={1}>
            <TextField
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                id="outlined-error"
                label="Search"
                defaultValue=""
                sx={{
                    width: '250px'
                }}
            />
        </Box>
        {modules ? <>
            <Grid2 container spacing={1}>
                {modules.filter(module => module.name.toLowerCase().includes(searchString.toLowerCase())).map(module => <Grid2 key={module.name} xs={12} sm={4} md={3}>
                    <CardActionArea onClick={() => navigate(`/module/${module.coreId}`)} sx={{ height: '100%' }}>
                        <Card variant="outlined" sx={{ height: '100%' }}>
                            <CardContent>
                                <Typography component="h3" variant="h5">
                                    {module.name}
                                </Typography>
                                <Typography>
                                    {module.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    </CardActionArea>
                </Grid2>
                )}
            </Grid2>

        </> : <></>}
    </Container>
}

export default ModuleList;