import axios from "axios";
import { hostname } from "../../server/server";

const path = 'auth';

const login = async (credentials) => {
    const res = await axios.post(`${hostname}/${path}/login`, credentials);
    return res.data;
}

const signup = async (credentials) => {
    const res = await axios.post(`${hostname}/${path}/register`, credentials);
    return res.data;
}

const logout = async () => {
    const res = await axios.get(`${hostname}/${path}/logout`);
    document.location.replace('/');
}

export {
    login,
    signup,
    logout
}