import { useSearchParams } from "react-router-dom";
import EditableForm from "../table/editableTable/EditableForm";
import { parseSearchParams } from "../../scripts/common";
import { getPrefilledValues } from "./formUtils";


const ModalFormContainer = props => {
    const { record, columns, references, choices } = props;
    const urlFilter = parseSearchParams(props.searchParams);
    const prefilledValues = getPrefilledValues(columns, references, choices, urlFilter);
    const prefilledRecord = {...record, ...prefilledValues};

    return <>
        <EditableForm
            {...props}
            record={prefilledRecord}
        />
    </>
}

export default ModalFormContainer;