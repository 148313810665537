import { actionTypes, conditionOperators } from "./logicUtils";

const applyClientRules = (clientRules, record, columns) => {
    let updatedRecord = {};
    let columnProps = {}
    clientRules.forEach(clientRule => {
        const updates = applyClientRule(clientRule, record, columns);
        if (updates) {
            updatedRecord = {...updatedRecord, ...updates.updatedProps};
            columnProps = {...columnProps, ...updates.columnProps};
        }
    })
    return {updatedRecord, columnProps};
}

const clientRuleConditionIsFulfilled = (clientRule, record) => {
    const { column, operator, value } = clientRule.condition;
    const conditionRecordValue = record[column];
    let conditionFulfilled = false;
    if (operator === conditionOperators.equal) {
        conditionFulfilled = conditionRecordValue === value;
    } else if (operator === conditionOperators.notEqual) {
        conditionFulfilled = conditionRecordValue !== value;
    }
    return conditionFulfilled;
}

const applyClientRuleAction = (clientRule, record) => {
    const { type, column, value } = clientRule.action;
    const updatedProps = {};
    const columnActions = {};

    if (type === actionTypes.setValue) {
        const actionRecordValue = record[column];
        if (actionRecordValue !== value) {
            updatedProps[column] = value;
        }
    } else if (type === actionTypes.hide) {
        columnActions.hide = 1;
    } else if (type === actionTypes.disable) {
        columnActions.disable = 1;
    }

    let columnProps = {};
    if (Object.keys(columnActions).length) {
        columnProps[column] = columnActions;
    }
    return { updatedProps, columnProps };
}

const applyClientRule = (clientRule, record, columns) => {
    if (clientRuleConditionIsFulfilled(clientRule, record)) {
        return applyClientRuleAction(clientRule, record, columns);
    }
}

export {
    applyClientRules
}