import { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import FieldError from './FieldError';
import { Disabled, editableColor, FieldLink, StyledInputField } from './Styles';
import theme from '../../../ui/theme/theme';
import { cellStates } from '../editableTable/tableTypes';

const Container = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    ${props => props.active && css`
        background-color: ${theme.colors.darkSpace10};
    `}
`

const InputFieldContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 4px;
    color: ${editableColor};
`

const ErrorContainer = styled.div`
    padding-right: 6px;
    display: flex;
    align-items: center;
`

const StringField = ({ object, column, updateObject, cellState, editable, error, formLink }) => {
    const ref = useRef(null);
    const value = object[column.name];
    const disabled = !editable;

    useEffect(() => {
        if (cellState === cellStates.ACTIVE && !disabled) {
            ref.current.focus();
        }
    }, [cellState]);

    const onChange = (newValue) => {
        updateObject({ [column.name]: newValue });
    }

    const active = cellState === cellStates.ACTIVE || cellState === cellStates.FORM;
    return <>
        <Container active={active && !disabled}>
            <InputFieldContainer>
                {!disabled ? <>
                    {active ? <>
                        <StyledInputField
                            ref={ref}
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            type={'text'}
                            /* maxWidth={maxWidth} */
                            active={active}
                        />
                    </> : <>
                        {formLink ? <>
                            <FieldLink to={formLink}>{value}</FieldLink>
                        </> : <>
                            {value}
                        </>}

                    </>}
                </> : <>
                    {formLink ? <>
                        <FieldLink to={formLink}>
                            <Disabled>{value}</Disabled>
                        </FieldLink>
                    </> : <>
                        <Disabled>{value}</Disabled>
                    </>}
                    
                </>}

            </InputFieldContainer>

            {error && error.message ? <>
                <ErrorContainer>
                    <FieldError error={error} />
                </ErrorContainer>
            </> : <></>}
        </Container>
    </>
}

export default StringField;