import React from 'react';
import styled, {keyframes} from 'styled-components';
import theme from '../theme/theme';

const Animation = keyframes`
    0% {
        top: 4px;
        height: 32px;
    }
    50%, 100% {
        top: 12px;
        height: 16px;
    }
`

const Spinner = styled.div`
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;

    div {
        display: inline-block;
        position: absolute;
        left: 4px;
        width: 6px;
        background: ${theme.colors.textGray};
        animation: ${Animation} 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }

    div:nth-child(1) {
        left: 4px;
        animation-delay: -0.24s;
    }

    div:nth-child(2) {
        left: 16px;
        animation-delay: -0.12s;
    }

    div:nth-child(3) { 
        left: 28px;
        animation-delay: 0;
    }
`



const LoadingSpinner = props => {
    return <Spinner {...props}><div></div><div></div><div></div></Spinner>
}

export default LoadingSpinner;
