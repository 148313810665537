import styled from "styled-components"
import InputField from "./InputField"
import { FaTimesCircle } from "react-icons/fa";
import { useRef } from "react";
import theme from "../theme/theme";

const Container = styled.div`
    position: relative;
    width: fit-content;
`

const StyledInputField = styled(InputField)`
    padding-right: 40px;
    border-radius: 4px;
    border: 1px solid ${theme.colors.darkSpace60};
    color: ${props => props.color || theme.colors.darkSpace70};
    &::placeholder {
        color: ${theme.colors.darkSpace40}80;
    }
`

const ClearIcon = styled(FaTimesCircle)`
    color: ${theme.colors.darkSpace50};
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
`

const SearchField = props => {
    const inputRef = useRef();

    const clearSearchField = () => {
        if (inputRef.current) {
            const setValue = Object.getOwnPropertyDescriptor(inputRef.current.__proto__, 'value').set;
            const event = new Event('input', { bubbles: true });

            setValue.call(inputRef.current, '');
            inputRef.current.dispatchEvent(event);
            inputRef.current.focus();
        }
    }

    return <Container>
        <StyledInputField ref={inputRef} {...props} placeholder={'Search'} />
        <ClearIcon onClick={() => clearSearchField()} />
    </Container>
}

export default SearchField;