import axios from "axios";
import { hostname } from "../../server/server";

const path = 'user';

const getCurrentUser = async () => {
    const res = await axios.get(`${hostname}/${path}/currentUser`);
    return res.data;
}

const getUser = async (coreId) => {
    const res = await axios.get(`${hostname}/auth/user/${coreId}`, {
        headers: {
            Authorization: null
        }
    });
    return res.data;
}

export {
    getCurrentUser,
    getUser
}