import { IconButton } from "@mui/material";
import { FaTrash } from "react-icons/fa";
import styled from "styled-components";

const Flex = styled.div`
    display: flex;
    justify-content: center;
`

const DeleteField = ({ onClick }) => {
    return <td>
        <Flex>
        <IconButton size="small" onClick={onClick} >
            <FaTrash />
        </IconButton>
        </Flex>
    </td>
}

export default DeleteField;
