import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import FormView from './modules/core/form/FormView';
import { useEffect, useState } from 'react';
import { getCurrentUser, getUser } from './modules/core/remote/user';
import AuthView from './modules/authentication/AuthView';
import { initAxios } from './modules/core/remote/server/server';
import ModuleEditor from './modules/module/moduleEditor/ModuleEditor';
import RoleEditor from './modules/module/moduleEditor/RoleEditor';
import UserContext from './modules/user/UserContext';
import TableView from './modules/core/table/TableView';
import { ThemeProvider } from '@mui/material';
import MainContainer from './modules/ui/components/MainContainer';
import muiTheme from './modules/ui/theme/muiTheme';
import ModuleList from './modules/module/ModuleList';
import ModuleOverview from './modules/module/ModuleOverview';

initAxios();

function App() {
  const [authUser, setAuthUser] = useState();
  const [user, setUser] = useState();

  const getUserData = async () => {
    try {

      const authUserRes = await getCurrentUser();
      await setUserHandler(authUserRes);
    } catch (err) {

    }
  }

  const setUserHandler = async (authUser) => {
    try {
      const userRes = await getUser(authUser.coreId);
      setUser(userRes);
      setAuthUser(authUser);
    } catch (err) {

    }
  }

  useEffect(() => {
    getUserData()
  }, []);


  return (<>
    {authUser && user ? <>
      <Router>
        <UserContext.Provider value={{
          authUser,
          user,
          setUser,
          isAdmin: authUser?.roles?.some(role => role.type === 3),
          isImpersonating: authUser?.coreId !== user.coreId
        }}>
          <ThemeProvider theme={muiTheme}>


          <MainContainer>
            <Routes>
              <Route path='/:apiPath/:table' element={
                <TableView />
              } />
              <Route path='/form/:apiPath/:table/:coreId' element={
                <FormView />
              } />
              <Route path='/moduleEditor/:coreId' element={
                <ModuleEditor />
              } />
              <Route path='/module/:coreId' element={
                <ModuleOverview />
              } />

              <Route path='/access/role/:coreId' element={
                <RoleEditor />
              } />
              <Route path='/' element={
                <ModuleList />
              } />
            </Routes>
          </MainContainer>
          </ThemeProvider>
        </UserContext.Provider>
      </Router>

    </> : <>
      <AuthView setUser={setUserHandler} />
    </>}
  </>);


}

export default App;
