import { parseSearchParams } from "../../../scripts/common";
import { conditionOperators } from "../fields/components/logicFields/logicUtils";
import { fieldTypes } from "../fields/fieldUtils";

const parseFilterItems = (searchParams) => {
    const urlFilter = parseSearchParams(searchParams);
    const items = Object.keys(urlFilter).map((key, ix) => ({
        column: key,
        value: urlFilter[key],
        operator: conditionOperators.equal
    }));
    return items;
}

const addDisplayValues = (filterItems, columns, references, choices) => {
    filterItems.forEach(item => {
        const column = columns.find(col => col.name === item.column);
        if (column) {
            if (column.type === fieldTypes.reference) {
                const reference = references[column.name]?.find(ref => ref.value === item.value);
                if (reference) {
                    item.displayValue = reference.displayValue;
                }
            } else if (column.type === fieldTypes.choice) {
                const choice = choices[column.name]?.find(choice => choice.value === item.value);
                if (choice) {
                    item.displayValue = choice.displayValue;
                }
            } else {
                item.displayValue = item.value;
            }
        }
    })
}

export {
    parseFilterItems,
    addDisplayValues
}