import theme from "../ui/theme/theme";

const isMobile = () => {
    return window.innerWidth <= theme.screenSizes.small;
}

const capitalizeFirstLetter = (string) => {
    return string.length > 1 ? string[0].toUpperCase() + string.slice(1, string.length) : string;
}

const getUuid = () => {
    return 'xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

const parseSearchParams = (searchParams) => {
    const urlFilter = {};
    for (const key of searchParams.keys()) {
        urlFilter[key] = searchParams.get(key);
    }
    return urlFilter;
}

const mergeSearchParams = (object) => {
    return new URLSearchParams(object);
}

const filterObject = (object, keys) => {
    const filteredObject = {};
    keys.forEach(key  => {
        filteredObject[key] = object[key];
    })
    return filteredObject;
}

const wait = async (ms) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export {
    isMobile,
    capitalizeFirstLetter,
    getUuid,
    parseSearchParams,
    mergeSearchParams,
    filterObject,
    wait
}