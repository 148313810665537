import styled from "styled-components";
import ModalField from "../ModalField";
import { actionTypes } from "./logicUtils";
import CellDropdown from "../CellDropdown";
import ColumnSelector from "./ColumnSelector";
import renderValueField from "./renderValueFields";
import useLogicBuilder from "./useLogicBuilder";


const ActionField = props => {
    return <ModalField {...props}>
        <ActionBuilder {...props} />
    </ModalField>
}

export default ActionField;

const ActionBuilder = ({ object: record, updateObject: updateRecord }) => {
    const builderObject = record.action || {};
    const updateBuilderObject = (updatedProps) => updateRecord({ action: { ...record.action, ...updatedProps } })
    const { object, updateObject, columns, references, choices, selectColumn } = useLogicBuilder({ table: record.table, object: builderObject, updateObject: updateBuilderObject })

    return <ActionBuilderFields
        object={object}
        updateObject={updateObject}
        columns={columns}
        references={references}
        choices={choices}
        selectColumn={selectColumn}
    />
}

const Container = styled.div`
    display: flex;

    >div {
        margin-right: 12px;
        padding: 10px;
    }
`

const ActionBuilderFields = ({
    object,
    updateObject,
    columns,
    references,
    choices,
    selectColumn,
}) => {

    const ActionTypeSelector = () => {
        const items = [{
            value: actionTypes.setValue,
            displayValue: 'Set value'
        }, {
            value: actionTypes.hide,
            displayValue: 'Hide field'
        }, {
            value: actionTypes.disable,
            displayValue: 'Disable field'
        }];

        const selectedItem = items.find(item => item.value === object.type);

        return <CellDropdown
            items={items}
            selectedItem={selectedItem}
            onClick={(value) => updateObject({ type: value })}
            cellControl={{}}
            excludeNone={true}
        />
    }

    return <Container>
        <div>
            <label>ActionType</label>
            <ActionTypeSelector />
        </div>
        <div>
            <label>Field</label>
            {columns ? <>
                <ColumnSelector
                    object={object}
                    columns={columns}
                    selectColumn={selectColumn}
                />
            </> : <></>}
        </div>
        {object.type === actionTypes.setValue ? <>
            <div>
                <label>Value</label>
                {columns ? <>
                    {renderValueField(object, updateObject, columns, references, choices)}
                </> : <></>}
            </div>
        </> : <></>}
    </Container>
}

