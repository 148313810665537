import axios from 'axios';

// Default axios config
const initAxios = () => {
    axios.defaults.withCredentials = true
}

const setAxiosDefaultAuth = (authString) => {
    axios.defaults.headers.common['Authorization'] = authString;
} 

export {
    initAxios,
    setAxiosDefaultAuth
}
