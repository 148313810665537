import styled, { css } from "styled-components"
import theme from "../../../ui/theme/theme";
import { cellStates } from "../editableTable/tableTypes";

const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    ${props => props.active && css`
      background-color: ${theme.colors.darkSpace10};
    `}
`

const StyledDateInput = styled.input`
    border: none;
    outline: none;
    background-color: transparent;
`

const DateTimeField = ({ object, column, updateObject, cellState, editable, error, formLink, dateOnly }) => {

    const active = cellState === cellStates.ACTIVE || cellState === cellStates.FORM;
    return <Container active={active}>
        <StyledDateInput
        type={dateOnly ? "date" : "datetime-local"}
        value={object[column.name]}
        onChange={(e) => updateObject({ [column.name]: e.target.value })}
    />
    </Container>
}

export const DateField = props => {
    return <DateTimeField {...props} dateOnly={true} />
}

export default DateTimeField;